import React, { useState } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import { useAuth } from "../contexts/AuthContext";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: '#ffffff',
    },
    background: {
      default: '#0a0a0a',
      paper: '#121212',
    },
    text: {
      primary: '#ffffff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#0a0a0a',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          '& fieldset': {
            borderColor: '#333',
          },
          '&:hover fieldset': {
            borderColor: '#444',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#666',
          },
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          textTransform: 'none',
          borderColor: '#333',
          color: '#fff',
          '&:hover': {
            borderColor: '#444',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
          },
        },
      },
    },
  },
});

export default function SignIn() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetStatus, setResetStatus] = useState('');
  const [loading, setLoading] = useState(false);

  async function signIn(email, password) {
    let { data, error: signInError } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (signInError) {
      console.log(signInError);
      alert("Incorrect login credentials.");
      return;
    }

    navigate('/');
  }

  async function signInWithGoogle() {
    let { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });

    if (error) {
      console.log(error);
      alert('Error signing in with Google.');
      return;
    }

    navigate('/');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signIn(data.get("email"), data.get("password"));
  };

  const handleResetPassword = async () => {
    if (!resetEmail) {
      setResetStatus('Please enter your email address');
      return;
    }

    try {
      setLoading(true);
      await resetPassword(resetEmail);
      setResetStatus('Password reset instructions have been sent to your email');
      setTimeout(() => {
        setOpenResetDialog(false);
        setResetStatus('');
      }, 3000);
    } catch (error) {
      setResetStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
            borderRadius: 2,
            border: '1px solid #333',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: '#fff' }}>
            Welcome back!
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputLabelProps={{
                style: { color: '#aaa' },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                style: { color: '#aaa' },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Sign In
            </Button>
            {/* <Button
              onClick={signInWithGoogle}
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              Sign in with Google
            </Button> */}
            <Grid container>
              <Grid item xs>
                <Link 
                  onClick={() => setOpenResetDialog(true)} 
                  variant="body2" 
                  sx={{ color: '#777', cursor: 'pointer' }}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2" sx={{ color: '#777' }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Dialog 
        open={openResetDialog} 
        onClose={() => setOpenResetDialog(false)}
        PaperProps={{
          sx: {
            background: '#111111',
            border: '1px solid #222',
            borderRadius: 1,
            minWidth: '400px',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.8)',
          }
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
        }}
      >
        <DialogTitle 
          sx={{ 
            color: '#fff', 
            borderBottom: '1px solid #222',
            background: '#111111',
            padding: '16px 24px',
            fontSize: '1rem',
            fontWeight: 500,
          }}
        >
          Reset Password
        </DialogTitle>
        <DialogContent 
          sx={{ 
            mt: 2, 
            mb: 1, 
            px: 3,
            background: '#111111',
          }}
        >
          <DialogContentText sx={{ 
            mb: 3, 
            color: '#666',
            fontSize: '0.875rem',
          }}>
            Enter your email address and we'll send you instructions to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reset-email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            InputLabelProps={{
              style: { color: '#666' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#0a0a0a',
                '& fieldset': {
                  borderColor: '#222',
                },
                '&:hover fieldset': {
                  borderColor: '#333',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#444',
                },
              },
              input: { 
                color: '#fff',
                fontSize: '0.875rem',
                '&::placeholder': {
                  color: '#444',
                  opacity: 1,
                },
              }
            }}
          />
          {resetStatus && (
            <Typography 
              sx={{ 
                mt: 2, 
                color: resetStatus.includes('sent') ? '#4caf50' : '#f44336',
                fontSize: '0.75rem',
              }}
            >
              {resetStatus}
            </Typography>
          )}
        </DialogContent>
        <DialogActions 
          sx={{ 
            borderTop: '1px solid #222',
            background: '#111111',
            padding: '12px 24px',
            gap: 1,
          }}
        >
          <Button 
            onClick={() => setOpenResetDialog(false)} 
            sx={{ 
              color: '#666',
              padding: '6px 12px',
              fontSize: '0.875rem',
              minWidth: '80px',
              '&:hover': {
                color: '#999',
                backgroundColor: '#0a0a0a'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleResetPassword} 
            disabled={loading}
            variant="outlined"
            sx={{
              borderColor: '#222',
              color: '#fff',
              padding: '6px 12px',
              fontSize: '0.875rem',
              minWidth: '120px',
              backgroundColor: '#0a0a0a',
              '&:hover': {
                borderColor: '#333',
                backgroundColor: '#161616'
              },
              '&:disabled': {
                borderColor: '#161616',
                color: '#444'
              }
            }}
          >
            {loading ? 'Sending...' : 'Send Reset Link'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
