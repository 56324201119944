import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  CircularProgress,
  useTheme,
  alpha,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import {
  Plus,
  ClipboardList,
  Clock,
  FileText,
  ChevronRight,
} from "lucide-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import AuditExplorer from "../components/AuditExplorer";
import AuditDetailPage from "../components/AuditDetailPage";
import AuditGuide from "../components/AuditGuide";
import { supabase } from "../supabaseClient";
import { useSidebar } from "../contexts/SidebarContext";
import { API_URL } from "../globals";

// Monochromatic theme consistent with other audit pages
const monoTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#1A1A1A',
    },
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#AAAAAA",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#AAAAAA",
      disabled: "#666666",
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    h5: {
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          borderRadius: 4,
          border: '1px solid rgba(255, 255, 255, 0.08)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          textTransform: 'none',
          fontWeight: 300,
          letterSpacing: '0.02em',
        },
      },
    },
  },
});

const Audit = () => {
  const [user, setUser] = useState(null);
  const [selectedAuditId, setSelectedAuditId] = useState(null);
  const [showNewAudit, setShowNewAudit] = useState(false);
  const [audits, setAudits] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isOpen, isHovered } = useSidebar();

  // Check for audit ID in URL parameters and initialize state
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setSelectedAuditId(id);
      setShowNewAudit(false);
    } else {
      setSelectedAuditId(null);
      setShowNewAudit(false);
    }
  }, [searchParams]);

  // Fetch audits
  useEffect(() => {
    const fetchAudits = async () => {
      setLoading(true);
      try {
        // Get the current audit ID from URL if available
        const id = searchParams.get("id");
        
        // Try to get session
        const { data } = await supabase.auth.getSession();
        
        // If user is not logged in, don't make any API calls
        if (!data?.session?.access_token) {
          setAudits([]);
          setLoading(false);
          return;
        }
        
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.session.access_token}`
        };
        
        // Only fetch audits if user is logged in
        const scansResponse = await fetch(`${API_URL}/audit/get_scans`, {
          method: 'GET',
          headers: headers
        });
        
        if (!scansResponse.ok) {
          const responseText = await scansResponse.text();
          console.error('API Error Response:', {
            status: scansResponse.status,
            statusText: scansResponse.statusText,
            url: `${API_URL}/audit/get_scans`,
            responseText: responseText.substring(0, 500)
          });
          throw new Error(`Failed to fetch audits: ${scansResponse.status} ${scansResponse.statusText}`);
        }
        
        const auditsData = await scansResponse.json();
        setAudits(auditsData);
        
        // If there's a specific audit ID, fetch its details
        if (id) {
          const scanResponse = await fetch(`${API_URL}/audit/get_scan?scan_id=${id}`, {
            method: 'GET',
            headers: headers
          });
          
          if (!scanResponse.ok) {
            const responseText = await scanResponse.text();
            console.error('API Error Response:', {
              status: scanResponse.status,
              statusText: scanResponse.statusText,
              url: `${API_URL}/audit/get_scan?scan_id=${id}`,
              responseText: responseText.substring(0, 500)
            });
            throw new Error(`Failed to fetch audit: ${scanResponse.status} ${scanResponse.statusText}`);
          }
          
          const auditData = await scanResponse.json();
          setSelectedAuditId(id);
        }
      } catch (error) {
        console.error('Error fetching audits:', error);
        
        // If there's an error fetching the specific audit, clear the selection
        if (searchParams.get("id")) {
          setSelectedAuditId(null);
          navigate("/audit", { replace: true });
        }
        
        // Fall back to empty audits list
        setAudits([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAudits();
  }, [searchParams, navigate]);

  const handleNewAudit = () => {
    setShowNewAudit(true);
    setSelectedAuditId(null);
    navigate("/audit");
  };

  const handleSelectAudit = (auditId) => {
    setSelectedAuditId(auditId);
    setShowNewAudit(false);
    navigate(`/audit?id=${auditId}`, { replace: true });
  };

  // Format relative time
  const getRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "just now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)}h ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)}d ago`;

    return date.toLocaleDateString();
  };

  // Get audit status icon and color
  const getStatusInfo = (status) => {
    switch (status) {
      case "completed":
        return {
          icon: <ClipboardList size={18} style={{ color: "#4CAF50" }} />,
          color: "#4CAF50",
        };
      case "in_progress":
        return {
          icon: <Clock size={18} style={{ color: "#FFFFFF" }} />,
          color: "#FFFFFF",
        };
      default:
        return {
          icon: <ClipboardList size={18} style={{ color: "#AAAAAA" }} />,
          color: "#AAAAAA",
        };
    }
  };

  // Calculate the sidebar width based on its state
  const navbarWidth = isOpen || isHovered ? 240 : 64;

  // Update this logic to only hide sidebar when viewing task details
  const isShowingTaskDetails = searchParams.get('task_id') !== null;

  return (
    <ThemeProvider theme={monoTheme}>
      <NavBar />
      <Box
        sx={{
          backgroundColor: "#121212",
          minHeight: "100vh",
          marginLeft: `${navbarWidth}px`,
          transition: "margin-left 0.2s",
          width: `calc(100% - ${navbarWidth}px)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100vh",
          }}
        >
          {/* Sidebar - Only hide when viewing task details */}
          {!isShowingTaskDetails && (
            <Box
              sx={{
                width: "280px",
                borderRight: "1px solid rgba(255, 255, 255, 0.08)",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Box sx={{ p: 2, pt: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleNewAudit}
                  sx={{
                    py: 1.5,
                    backgroundColor: '#0a0a0a',
                    borderColor: '#333',
                    color: '#fff',
                    fontSize: '0.95rem',
                    fontWeight: 400,
                    '&:hover': {
                      borderColor: '#444',
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                    ...(showNewAudit && {
                      borderColor: '#444',
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    })
                  }}
                >
                  New Audit
                </Button>
              </Box>

              <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.08)" }} />

              <Box
                sx={{
                  flex: 1,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: alpha("#FFFFFF", 0.1),
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: alpha("#FFFFFF", 0.2),
                  },
                }}
              >
                {loading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : audits.length === 0 ? (
                  <Box sx={{ p: 2, textAlign: "center" }}>
                    <Typography variant="body2" color="text.secondary">
                      No audits found
                    </Typography>
                  </Box>
                ) : (
                  <List>
                    {audits.map((audit) => {
                      // Determine status based on test_count and total_testable_nodes
                      const status = audit.test_count >= (audit.total_testable_nodes || 0) 
                        ? "completed" 
                        : "in_progress";
                      
                      const statusInfo = getStatusInfo(status);
                      const isSelected = selectedAuditId === audit.id.toString();
                      
                      // Extract scope name from JSON if available
                      let auditName = "Unnamed Audit";
                      if (audit.studio && typeof audit.studio === 'object') {
                        auditName = audit.studio.display_name;
                      } else if (audit.scope && typeof audit.scope === 'string') {
                        try {
                          const scopeObj = JSON.parse(audit.scope);
                          auditName = scopeObj.name || `Audit #${audit.id}`;
                        } catch (e) {
                          auditName = `Audit #${audit.id}`;
                        }
                      }

                      return (
                        <ListItem
                          key={audit.id}
                          button
                          selected={isSelected}
                          onClick={() => handleSelectAudit(audit.id.toString())}
                          sx={{
                            mb: 0.5,
                            mx: 1,
                            borderRadius: 1,
                            bgcolor: isSelected
                              ? alpha("#FFFFFF", 0.1)
                              : "transparent",
                            "&:hover": {
                              bgcolor: isSelected
                                ? alpha("#FFFFFF", 0.12)
                                : alpha("#FFFFFF", 0.05),
                            },
                            transition: "background-color 0.2s",
                            "&.Mui-selected": {
                              bgcolor: alpha("#FFFFFF", 0.1),
                              "&:hover": {
                                bgcolor: alpha("#FFFFFF", 0.12),
                              },
                            },
                          }}
                        >
                          <ListItemText
                            primary={auditName}
                            primaryTypographyProps={{
                              noWrap: true,
                              fontWeight: isSelected ? 400 : 300,
                              fontSize: "0.9rem",
                              color: "text.primary",
                            }}
                            secondary={getRelativeTime(audit.created_at)}
                            secondaryTypographyProps={{
                              fontSize: "0.75rem",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
            </Box>
          )}

          {/* Main Content */}
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: { xs: 2, md: 3 },
              bgcolor: "background.default",
            }}
          >
            {showNewAudit ? (
              <AuditExplorer />
            ) : selectedAuditId ? (
              <AuditDetailPage 
                id={selectedAuditId} 
              />
            ) : (
              <AuditGuide />
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Audit;
