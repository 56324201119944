import { createClient } from '@supabase/supabase-js';

// Fetch Supabase URL and Anon Key from environment variables
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '__REACT_APP_SUPABASE_URL__';
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || '__REACT_APP_SUPABASE_ANON_KEY__';
const siteUrl = process.env.REACT_APP_SITE_URL || 'https://app.fumedev.com';

// Safety checks for the presence of required environment variables
if (!supabaseUrl) {
  throw new Error('Supabase URL is missing. Please define SUPABASE_URL in your environment variables.');
}
if (!supabaseAnonKey) {
  throw new Error('Supabase Anonymous Key is missing. Please define SUPABASE_ANON_KEY in your environment variables.');
}

// Initialize the Supabase client with the url, anon key, and site URL
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    site_url: siteUrl,
  },
});
