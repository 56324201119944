import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  CssBaseline,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    background: {
      default: '#121212',
      paper: '#121212',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#555',
          },
          '&:hover fieldset': {
            borderColor: '#777',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#aaa',
          },
          color: '#fff',
        },
      },
    },
  },
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const { data, error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;
      
      setMessage('Password updated successfully!');
      setTimeout(() => navigate('/signin'), 2000);
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs" sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 4,
            borderRadius: 2,
            border: '1px solid #333',
            width: '100%',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: '#fff', mb: 3 }}>
            Reset Your Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputLabelProps={{
                style: { color: '#aaa' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#555',
                  },
                  '&:hover fieldset': {
                    borderColor: '#777',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#aaa',
                  },
                },
              }}
            />
            {message && (
              <Typography 
                sx={{ 
                  mt: 2,
                  color: message.includes('success') ? '#4caf50' : '#f44336',
                  textAlign: 'center'
                }}
              >
                {message}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ 
                mt: 3, 
                mb: 2,
                borderColor: '#555',
                color: '#fff',
                '&:hover': {
                  borderColor: '#777',
                  backgroundColor: 'rgba(255, 255, 255, 0.08)'
                }
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Reset Password'}
            </Button>
            <Button
              fullWidth
              variant="text"
              onClick={() => navigate('/signin')}
              sx={{ 
                color: '#777',
                '&:hover': {
                  color: '#fff',
                  backgroundColor: 'rgba(255, 255, 255, 0.08)'
                }
              }}
            >
              Back to Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
} 