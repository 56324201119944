import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Paper, CircularProgress, TextField, IconButton, Tooltip, Select, MenuItem, FormControl, InputLabel, createTheme, ThemeProvider, CssBaseline, alpha } from '@mui/material';
import { Folder, File, Code, ChevronDown, ChevronUp, Search, Check, Lightbulb, AlertCircle } from 'lucide-react';
import { getSampleCodebaseData, fetchUserRepositories, fetchCodebaseStructure } from '../services/codebaseService';
import NavBar from './NavBar';
import axios from 'axios';
import { API_URL } from '../globals';
import { supabase } from '../supabaseClient';

// Minimalistic monochromatic theme
const monoTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#1A1A1A',
    },
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#AAAAAA",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#AAAAAA",
      disabled: "#666666",
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    h5: {
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          borderRadius: 4,
          border: '1px solid rgba(255, 255, 255, 0.08)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          textTransform: 'none',
          fontWeight: 300,
          letterSpacing: '0.02em',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
          },
        },
      },
    },
  },
});

// Node Component
const Node = ({ node, expanded, onToggle, level = 0, isSelected, onSelect, parentSelected = false }) => {
  // Consider this node selected if it's either directly selected or has a selected parent
  const effectivelySelected = isSelected || parentSelected;

  const getIcon = () => {
    switch (node.type) {
      case 'directory':
        return <Folder size={16} color="#FFFFFF" />;
      case 'file':
        return <File size={16} color="#FFFFFF" />;
      case 'symbol':
        return <Code size={16} color="#FFFFFF" />;
      default:
        return null;
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      onSelect(node);
    } else {
      onToggle(node);
    }
  };

  const handleSelect = (e) => {
    e.stopPropagation();
    onSelect(node);
  };

  return (
    <div style={{ marginLeft: `${level * 16}px` }}>
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px 6px',
          cursor: 'pointer',
          backgroundColor: effectivelySelected 
            ? alpha('#FFFFFF', 0.1) 
            : expanded 
              ? alpha('#FFFFFF', 0.05) 
              : 'transparent',
          borderRadius: '2px',
          marginBottom: '1px',
          transition: 'all 0.2s ease',
          border: effectivelySelected ? `1px solid ${alpha('#FFFFFF', 0.2)}` : '1px solid transparent',
        }}
      >
        <Box 
          component="span" 
          onClick={handleSelect}
          sx={{
            width: 16,
            height: 16,
            border: '1px solid',
            borderColor: effectivelySelected ? 'primary.main' : 'text.secondary',
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
            backgroundColor: effectivelySelected ? 'primary.main' : 'transparent',
            '&:hover': {
              borderColor: 'primary.main',
            }
          }}
        >
          {effectivelySelected && (
            <Check size={12} color="#1A1A1A" />
          )}
        </Box>
        {getIcon()}
        <Typography variant="body2" sx={{ ml: 0.75, fontWeight: expanded ? 400 : 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.85rem' }}>
          {node.name}
        </Typography>
        
        {node.type === 'directory' && (
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); onToggle(node); }} sx={{ ml: 'auto', color: 'text.secondary', p: 0.25 }}>
            {expanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </IconButton>
        )}
      </div>
    </div>
  );
};

// CodebaseGraph Component
const CodebaseGraph = () => {
  const [data, setData] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState(new Set(['root']));
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [directlySelectedNodes, setDirectlySelectedNodes] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [studios, setStudios] = useState([]);
  const [selectedStudio, setSelectedStudio] = useState('');
  const [loadingStudios, setLoadingStudios] = useState(false);
  const [totalSelectedFiles, setTotalSelectedFiles] = useState(0);
  const [calculatingCost, setCalculatingCost] = useState(false);
  const [auditCost, setScanCost] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [stripePaymentLink, setStripePaymentLink] = useState(null);
  
  // Cache file paths for each directory to avoid recalculation
  const [filePathsCache] = useState(new Map());

  // Helper function to get node's unique ID
  const getNodeId = useCallback((node) => {
    if (node.type === 'file') return node.path;
    
    // For directories, ensure uniqueness by using full path or creating a composite key
    if (node.path) return `dir:${node.path}`;
    
    // If no path is available, create a unique ID using parent path + name
    if (node.parent) {
      return `dir:${node.parent}/${node.name}`;
    }
    
    // Fallback for root level with no path
    return `dir:root/${node.name}`;
  }, []);

  // Helper function to get all file paths from a node with caching
  const getAllFilePaths = useCallback((node) => {
    if (!node) return [];
    
    // For files, return immediately
    if (node.type === 'file') return [node.path];
    
    // For directories, check cache first
    if (node.type === 'directory') {
      const cacheKey = getNodeId(node);
      if (filePathsCache.has(cacheKey)) {
        return filePathsCache.get(cacheKey);
      }

      let paths = [];
      if (node.children) {
        // Recursively get paths from all children
        node.children.forEach(child => {
          if (child.type === 'file') {
            paths.push(child.path);
          } else if (child.type === 'directory') {
            paths = paths.concat(getAllFilePaths(child));
          }
        });
      }
      
      // Store in cache
      filePathsCache.set(cacheKey, paths);
      return paths;
    }
    return [];
  }, [filePathsCache, getNodeId]);

  // Memoize expensive operations for the Node component
  const MemoizedNode = useMemo(() => React.memo(Node, (prevProps, nextProps) => {
    return (
      prevProps.expanded === nextProps.expanded &&
      prevProps.isSelected === nextProps.isSelected &&
      prevProps.parentSelected === nextProps.parentSelected &&
      prevProps.level === nextProps.level &&
      prevProps.node === nextProps.node
    );
  }), []);

  // Function to fetch studios
  const fetchStudios = async () => {
    try {
      setLoadingStudios(true);
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/studio/seeds`, { headers });
      
      if (response.data) {
        const readyStudios = response.data.filter(studio => studio.status === "READY" || studio.status === "BUSY");
        const options = readyStudios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));
        setStudios(options);
      }
    } catch (error) {
      console.error('Failed to fetch studios:', error);
    } finally {
      setLoadingStudios(false);
    }
  };

  useEffect(() => {
    fetchStudios();
  }, []);

  // Function to transform API response into tree structure
  const transformToTreeStructure = (response) => {
    const result = [];

    // Helper function to process directories with proper parent paths
    const processDir = (dirData, container, parentPath = '') => {
      // Process files in this directory
      if (Array.isArray(dirData.files)) {
        dirData.files.forEach(filePath => {
          const parts = filePath.split('/');
          const fileName = parts[parts.length - 1];
          container.push({
            name: fileName,
            type: 'file',
            path: filePath, // Store the full path
            parent: parentPath
          });
        });
      }

      // Process subdirectories
      if (dirData.dirs) {
        Object.entries(dirData.dirs).forEach(([subDirName, subDirData]) => {
          const newDirPath = parentPath ? `${parentPath}/${subDirName}` : subDirName;
          const newDir = {
            name: subDirName,
            type: 'directory',
            children: [],
            parent: parentPath,
            path: newDirPath // Store complete path for directories too
          };
          container.push(newDir);
          processDir(subDirData, newDir.children, newDirPath);
        });
      }
    };

    // Process root level directories
    if (response.dirs) {
      Object.entries(response.dirs).forEach(([dirName, dirData]) => {
        const newDir = {
          name: dirName,
          type: 'directory',
          children: [],
          path: dirName // Store the path for root directories
        };
        result.push(newDir);
        processDir(dirData, newDir.children, dirName);
      });
    }

    // Process root level files if any
    if (Array.isArray(response.files)) {
      response.files.forEach(filePath => {
        const parts = filePath.split('/');
        const fileName = parts[parts.length - 1];
        result.push({
          name: fileName,
          type: 'file',
          path: filePath // Store the full path
        });
      });
    }

    // Sort items alphabetically with directories first
    const sortItems = (items) => {
      items.sort((a, b) => {
        if (a.type === b.type) {
          return a.name.localeCompare(b.name);
        }
        return a.type === 'directory' ? -1 : 1;
      });
      items.forEach(item => {
        if (item.type === 'directory' && item.children) {
          sortItems(item.children);
        }
      });
    };
    
    sortItems(result);
    return result;
  };

  // Function to fetch codebase structure
  const fetchData = async () => {
    if (!selectedStudio) return;
    
    try {
      setLoading(true);
      setSelectedItems(new Set()); // Clear selected items when changing studio
      setDirectlySelectedNodes(new Set()); // Clear directly selected nodes
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/audit/codebase_graph`, {
        headers,
        params: {
          studio_id: selectedStudio
        }
      });
      
      if (response.data) {
        const treeData = transformToTreeStructure(response.data);
        setData(treeData);
        setFilteredData(treeData);
        filePathsCache.clear(); // Clear cache when data changes
      }
    } catch (error) {
      console.error('Error fetching codebase structure:', error);
      // Show error state in the UI
      setData([{ 
        name: 'Failed to load codebase structure', 
        type: 'file' 
      }]);
      setFilteredData(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedStudio) {
      fetchData();
    }
  }, [selectedStudio]);

  useEffect(() => {
    if (!data || searchTerm.trim() === '') {
      setFilteredData(data);
      return;
    }

    const search = searchTerm.toLowerCase();
    
    // Helper function to recursively filter nodes
    const filterNode = (node) => {
      // Handle file nodes
      if (node.type === 'file') {
        return (node.name.toLowerCase().includes(search) || 
                (node.path && node.path.toLowerCase().includes(search))) ? node : null;
      }

      // Handle directory nodes
      if (node.type === 'directory') {
        // Check if directory name matches
        const nameMatches = node.name.toLowerCase().includes(search);

        // Filter children if they exist
        let filteredChildren = [];
        if (node.children) {
          filteredChildren = node.children
            .map(filterNode)
            .filter(Boolean);
        }

        // Return node if name matches or if it has matching children
        if (nameMatches || filteredChildren.length > 0) {
          return {
            ...node,
            children: filteredChildren
          };
        }
      }

      return null;
    };

    const filtered = filterNode(data);
    setFilteredData(filtered || { name: 'No results', type: 'directory', children: [] });
  }, [searchTerm, data]);

  const toggleNode = useCallback((node) => {
    setExpandedNodes(prev => {
      const newSet = new Set(prev);
      const nodeId = getNodeId(node);
      if (newSet.has(nodeId)) {
        newSet.delete(nodeId);
      } else {
        newSet.add(nodeId);
      }
      return newSet;
    });
  }, [getNodeId]);

  const handleSelect = useCallback((node) => {
    // Clear error message when selection changes
    setErrorMessage(null);
    
    // Reset audit cost and payment link when selection changes
    setScanCost(null);
    setStripePaymentLink(null);
    
    // Update directly selected nodes
    setDirectlySelectedNodes(prev => {
      const newSet = new Set(prev);
      const nodeId = getNodeId(node);
      
      if (newSet.has(nodeId)) {
        newSet.delete(nodeId);
      } else {
        newSet.add(nodeId);
      }
      return newSet;
    });
    
    // Update selected items (file paths for UI display)
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      
      if (node.type === 'file') {
        // For files, simply toggle the file path
        if (newSet.has(node.path)) {
          newSet.delete(node.path);
        } else {
          newSet.add(node.path);
        }
      } else if (node.type === 'directory') {
        // Get all file paths for the directory including nested files
        const filePaths = getAllFilePaths(node);
        
        // Check if all files in this directory (including nested) are selected
        const allFilesSelected = filePaths.length > 0 && 
          filePaths.every(path => newSet.has(path));
        
        if (allFilesSelected) {
          // If all files are selected, unselect them all
          filePaths.forEach(path => newSet.delete(path));
        } else {
          // If not all files are selected, select them all
          filePaths.forEach(path => newSet.add(path));
        }
      }
      
      return newSet;
    });
  }, [getAllFilePaths, getNodeId]);

  // Memoize the isSelected calculation
  const isNodeSelected = useCallback((node, selectedItems) => {
    if (node.type === 'file') {
      return selectedItems.has(node.path);
    }
    if (node.type === 'directory') {
      // Get all file paths for the directory including nested files
      const filePaths = getAllFilePaths(node);
      
      return filePaths.length > 0 && 
        filePaths.every(path => selectedItems.has(path));
    }
    return false;
  }, [getAllFilePaths]);

  // Memoize renderTree for better performance
  const renderTree = useCallback((nodes, level = 0, parentSelected = false) => {
    if (!nodes) return null;
    
    // If it's a single node
    if (!Array.isArray(nodes)) {
      const isExpanded = expandedNodes.has(getNodeId(nodes));
      const isSelected = isNodeSelected(nodes, selectedItems);
      const effectivelySelected = isSelected || parentSelected;
      
      return (
        <div key={`${getNodeId(nodes)}-${level}`}>
          <MemoizedNode 
            node={nodes} 
            expanded={isExpanded} 
            onToggle={toggleNode} 
            level={level}
            isSelected={isSelected}
            onSelect={handleSelect}
            parentSelected={parentSelected}
          />
          
          {isExpanded && nodes.type === 'directory' && nodes.children && (
            <div>
              {nodes.children.map(child => renderTree(child, level + 1, effectivelySelected))}
            </div>
          )}
        </div>
      );
    }
    
    // If it's an array of nodes
    return nodes.map(node => renderTree(node, level, parentSelected));
  }, [expandedNodes, selectedItems, toggleNode, handleSelect, isNodeSelected, MemoizedNode, getNodeId]);

  // Clear cache when data changes
  useEffect(() => {
    filePathsCache.clear();
  }, [data, filePathsCache]);

  // Count total files in data (for debugging)
  const getTotalFilesInData = useCallback(() => {
    if (!data) return 0;
    
    const countFilesInNode = (node) => {
      if (node.type === 'file') return 1;
      if (node.type === 'directory' && node.children) {
        return node.children.reduce((sum, child) => sum + countFilesInNode(child), 0);
      }
      return 0;
    };
    
    if (Array.isArray(data)) {
      return data.reduce((sum, node) => sum + countFilesInNode(node), 0);
    }
    
    return countFilesInNode(data);
  }, [data]);

  // Function to recursively count files in a node
  const countFiles = useCallback((node) => {
    if (!node) return 0;
    if (node.type === 'file') return 1;
    if (node.type === 'directory' && node.children) {
      return node.children.reduce((sum, child) => sum + countFiles(child), 0);
    }
    return 0;
  }, []);

  // Function to find a node by its path or name
  const findNode = useCallback((nodes, path) => {
    if (!nodes) return null;
    if (!Array.isArray(nodes)) {
      if (nodes.path === path) return nodes;
      if (nodes.children) {
        for (const child of nodes.children) {
          const found = findNode(child, path);
          if (found) return found;
        }
      }
      return null;
    }
    for (const node of nodes) {
      const found = findNode(node, path);
      if (found) return found;
    }
    return null;
  }, []);

  // Debug info about total files
  const totalFilesInData = useMemo(() => getTotalFilesInData(), [getTotalFilesInData]);

  // Better file counting for selected items
  useEffect(() => {
    if (!data) {
      setTotalSelectedFiles(0);
      return;
    }
    
    // We're counting files using the size of selectedItems
    // since we've ensured each entry is a unique file path
    setTotalSelectedFiles(selectedItems.size);
  }, [selectedItems, data]);

  // Calculate audit cost
  const calculateScanCost = async () => {
    try {
      setCalculatingCost(true);
      setScanCost(null);
      setStripePaymentLink(null);
      setErrorMessage(null); // Clear any previous error messages
      
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      // Get directly selected files and directories
      const selectedFiles = [];
      const selectedDirectories = [];
      
      directlySelectedNodes.forEach(nodeId => {
        // For file nodes, the nodeId will be the file path
        // For directory nodes, the nodeId will be in format "dir:path"
        if (nodeId.startsWith('dir:')) {
          // Extract directory path from the nodeId
          const dirPath = nodeId.substring(4); // Remove 'dir:' prefix
          selectedDirectories.push(dirPath);
        } else {
          // It's a file path
          selectedFiles.push(nodeId);
        }
      });
      
      const response = await axios.get(`${API_URL}/audit/calculate_cost`, {
        headers,
        params: {
          studio_id: selectedStudio,
          files: selectedFiles.join(','),
          directories: selectedDirectories.join(',')
        }
      });
      
      // Check if response contains an error
      if (response.data && response.data.error) {
        setErrorMessage(response.data.error);
      } else if (response.data && typeof response.data.cost === 'number') {
        setScanCost(response.data.cost);
        // Store the Stripe payment link if it exists
        if (response.data.stripe_payment_link) {
          setStripePaymentLink(response.data.stripe_payment_link);
        }
      } else {
        setErrorMessage("Unexpected response format from server");
      }
    } catch (error) {
      console.error('Failed to calculate audit cost:', error);
      setErrorMessage(error.response?.data?.error || error.message || "Failed to calculate audit cost");
    } finally {
      setCalculatingCost(false);
    }
  };

  // Handle the Start Scan button click
  const handleStartScan = () => {
    if (stripePaymentLink) {
      window.location.href = stripePaymentLink;
    } else {
      console.error('No Stripe payment link available');
      setErrorMessage('Payment link not available. Please try calculating the cost again.');
    }
  };

  return (
    <Paper elevation={1} sx={{ p: 2, height: '100%', overflowY: 'auto', bgcolor: 'background.paper' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2" sx={{ color: 'text.primary', fontWeight: 300 }}>
          Define scope
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <Search size={18} color="#666666" style={{ marginRight: '4px' }} />,
            }}
            sx={{ 
              width: '180px',
              '& .MuiOutlinedInput-root': {
                bgcolor: alpha('#FFFFFF', 0.02),
                '&:hover': {
                  bgcolor: alpha('#FFFFFF', 0.04),
                }
              }
            }}
          />
        </Box>
      </Box>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="studio-select-label" sx={{ color: 'text.secondary' }}>Studio</InputLabel>
        <Select
          labelId="studio-select-label"
          id="studio-select"
          value={selectedStudio}
          label="Studio"
          onChange={(e) => {
            setSelectedStudio(e.target.value);
            setErrorMessage(null); // Clear error message when studio changes
          }}
          disabled={loadingStudios}
          sx={{ 
            bgcolor: alpha('#FFFFFF', 0.02),
            '&:hover': {
              bgcolor: alpha('#FFFFFF', 0.04),
            }
          }}
        >
          {studios.map(studio => (
            <MenuItem key={studio.value} value={studio.value}>
              {studio.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <CircularProgress sx={{ color: '#FFFFFF' }} size={24} />
        </Box>
      ) : !selectedStudio ? (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          height: 300,
          gap: 2,
          p: 2,
          textAlign: 'center',
          bgcolor: alpha('#FFFFFF', 0.01),
          borderRadius: 1
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            mb: 1
          }}>
            <ChevronUp size={24} style={{ color: alpha('#FFFFFF', 0.4) }} />
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Select a studio first
            </Typography>
          </Box>
          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 300 }}>
            No Studio Selected
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Please choose a studio from the dropdown menu above to view its codebase structure
          </Typography>
        </Box>
      ) : filteredData ? (
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          minHeight: '400px'
        }}>
          <Box sx={{ 
            bgcolor: alpha('#FFFFFF', 0.01),
            borderRadius: 1,
            p: 1,
            flexGrow: 1
          }}>
            <div className="codebase-tree">
              {renderTree(filteredData)}
            </div>
          </Box>
          
          <Box sx={{
            width: '200px',
            bgcolor: alpha('#FFFFFF', 0.01),
            borderRadius: 1,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: 'fit-content',
            border: `1px solid ${alpha('#FFFFFF', 0.08)}`
          }}>
            <Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                Selected Files
              </Typography>
              <Typography variant="h5" sx={{ color: 'text.primary', fontWeight: 400 }}>
                {totalSelectedFiles}
              </Typography>
            </Box>
            
            {errorMessage && (
              <Box sx={{ 
                p: 1.5, 
                bgcolor: alpha('#FF4D4F', 0.1),
                borderRadius: 1,
                border: `1px solid ${alpha('#FF4D4F', 0.3)}`,
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AlertCircle size={16} color="#FF4D4F" />
                  <Typography variant="body2" sx={{ color: alpha('#FF4D4F', 0.9), fontWeight: 500 }}>
                    Error
                  </Typography>
                </Box>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: alpha('#FF4D4F', 0.8),
                    fontSize: '0.8rem',
                    lineHeight: 1.4
                  }}
                >
                  {errorMessage}
                </Typography>
              </Box>
            )}
            
            {auditCost !== null && (
              <Box sx={{ 
                p: 1.5, 
                bgcolor: alpha('#FFFFFF', 0.03),
                borderRadius: 1,
                border: `1px solid ${alpha('#FFFFFF', 0.1)}`
              }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                  Cost
                </Typography>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: 'text.primary', 
                    fontWeight: 500,
                    fontSize: {
                      xs: '1.2rem',
                      sm: '1.4rem'
                    },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: 1.1
                  }}
                >
                  ${auditCost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </Typography>
              </Box>
            )}
            
            <Box 
              component="button"
              onClick={auditCost !== null ? handleStartScan : calculateScanCost}
              sx={{
                width: '100%',
                bgcolor: auditCost !== null 
                  ? 'transparent' 
                  : alpha('#FFFFFF', 0.05),
                background: auditCost !== null 
                  ? `linear-gradient(135deg, 
                      rgba(41, 225, 255, 0.25) 0%, 
                      rgba(56, 114, 255, 0.25) 100%)`
                  : 'none',
                color: auditCost !== null 
                  ? '#FFFFFF' 
                  : 'text.primary',
                border: auditCost !== null 
                  ? `1px solid rgba(41, 225, 255, 0.5)` 
                  : `1px solid ${alpha('#FFFFFF', 0.1)}`,
                borderRadius: '8px',
                py: auditCost !== null ? 1.2 : 1,
                px: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                transition: 'all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                position: 'relative',
                fontWeight: auditCost !== null ? 500 : 400,
                fontSize: auditCost !== null ? '0.95rem' : '0.875rem',
                letterSpacing: auditCost !== null ? '0.05em' : 'normal',
                textShadow: auditCost !== null ? '0 0 8px rgba(41, 225, 255, 0.8)' : 'none',
                perspective: '1000px',
                transformStyle: 'preserve-3d',
                transform: auditCost !== null 
                  ? 'translateZ(0) rotateX(-5deg)' 
                  : 'none',
                boxShadow: auditCost !== null 
                  ? `0 6px 0 rgba(20, 70, 140, 0.5),
                     0 8px 16px rgba(0, 0, 0, 0.3),
                     0 0 0 1px rgba(41, 225, 255, 0.4),
                     inset 0 1px 1px rgba(255, 255, 255, 0.3),
                     inset 0 -1px 1px rgba(0, 0, 0, 0.2),
                     0 0 15px rgba(41, 225, 255, 0.3)`
                  : 'none',
                '&::before': auditCost !== null ? {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: '8px',
                  background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.1) 100%)',
                  pointerEvents: 'none',
                  zIndex: 1
                } : {},
                '&:hover': {
                  bgcolor: auditCost !== null 
                    ? 'transparent' 
                    : alpha('#FFFFFF', 0.08),
                  background: auditCost !== null 
                    ? `linear-gradient(135deg, 
                        rgba(41, 225, 255, 0.3) 0%, 
                        rgba(56, 114, 255, 0.3) 100%)`
                    : 'none',
                  borderColor: auditCost !== null 
                    ? 'rgba(41, 225, 255, 0.7)' 
                    : alpha('#FFFFFF', 0.15),
                  boxShadow: auditCost !== null 
                    ? `0 2px 0 rgba(20, 70, 140, 0.5),
                       0 4px 8px rgba(0, 0, 0, 0.3),
                       0 0 0 1px rgba(41, 225, 255, 0.5),
                       inset 0 1px 1px rgba(255, 255, 255, 0.4),
                       inset 0 -1px 1px rgba(0, 0, 0, 0.3),
                       0 0 20px rgba(41, 225, 255, 0.4)`
                    : 'none',
                  transform: auditCost !== null 
                    ? 'translateY(4px) translateZ(0) rotateX(0deg)' 
                    : 'none',
                  textShadow: auditCost !== null ? '0 0 12px rgba(41, 225, 255, 1)' : 'none',
                },
                '&:active': {
                  transform: auditCost !== null 
                    ? 'translateY(6px) translateZ(0) rotateX(2deg)' 
                    : 'none',
                  boxShadow: auditCost !== null 
                    ? `0 0 0 rgba(20, 70, 140, 0.5),
                       0 2px 4px rgba(0, 0, 0, 0.3),
                       0 0 0 1px rgba(41, 225, 255, 0.5),
                       inset 0 1px 2px rgba(0, 0, 0, 0.2),
                       0 0 10px rgba(41, 225, 255, 0.3)`
                    : 'none',
                  transition: 'all 0.1s cubic-bezier(0.6, 0.04, 0.98, 0.335)',
                },
                '&:disabled': {
                  opacity: 0.5,
                  cursor: 'not-allowed',
                  boxShadow: 'none',
                  textShadow: 'none',
                  transform: 'none',
                }
              }}
              disabled={selectedItems.size === 0 || calculatingCost}
            >
              {calculatingCost ? (
                <>
                  <CircularProgress size={16} sx={{ color: 'text.primary' }} />
                  <span>Calculating...</span>
                </>
              ) : auditCost !== null ? (
                <>
                  <span style={{ position: 'relative', zIndex: 2 }}>Start Scan</span>
                  {auditCost !== null && (
                    <>
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          borderRadius: '8px',
                          animation: 'pulse 3s infinite',
                          background: 'radial-gradient(circle, rgba(41, 225, 255, 0.15) 0%, rgba(41, 225, 255, 0) 70%)',
                          opacity: 0.8,
                          pointerEvents: 'none',
                          '@keyframes pulse': {
                            '0%': { opacity: 0.4, transform: 'scale(0.98)' },
                            '50%': { opacity: 0.8, transform: 'scale(1.01)' },
                            '100%': { opacity: 0.4, transform: 'scale(0.98)' },
                          },
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '80%',
                          height: '5px',
                          bottom: '3px',
                          left: '10%',
                          borderRadius: '50%',
                          filter: 'blur(4px)',
                          background: 'rgba(41, 225, 255, 0.5)',
                          opacity: 0.5,
                          pointerEvents: 'none',
                          zIndex: 0
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                'Calculate Cost'
              )}
            </Box>
          </Box>
        </Box>
      ) : null}

      {/* Add debug info if needed */}
      {false && (
        <Box sx={{ mt: 2, p: 2, bgcolor: alpha('#FFFFFF', 0.05), borderRadius: 1 }}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Debug Info: Total files in data: {totalFilesInData}, Selected files: {totalSelectedFiles}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

// Main ScanExplorer Component
const ScanExplorer = () => {
  const [user, setUser] = useState(null);

  return (
    <Box sx={{ 
      minHeight: '100%',
      bgcolor: 'background.default',
    }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ color: 'text.primary', fontWeight: 300, mb: 2 }}>
          Start a new audit
        </Typography>
      </Box>
      
      <CodebaseGraph />
      
      <Box sx={{ 
        mt: 3, 
        p: 1.5, 
        borderRadius: 1, 
        bgcolor: alpha('#FFFFFF', 0.02),
        border: `1px solid ${alpha('#FFFFFF', 0.05)}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Lightbulb size={16} style={{ marginRight: '8px', color: alpha('#FFFFFF', 0.6) }} />
          <Typography variant="body2" sx={{ color: alpha('#FFFFFF', 0.6), fontSize: '0.8rem' }}>
            Select files or entire directories to be included in the audit. Cost is calculated based on the code size and complexity.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ScanExplorer;
