import React, { useState, useEffect, useCallback } from "react";
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  LinearProgress, 
  TextField,
  InputAdornment,
  alpha,
  CircularProgress,
  Pagination,
  Button
} from "@mui/material";
import { Search, CheckCircle, XCircle, Clock, AlertCircle, Hourglass, Play, ArrowDown } from 'lucide-react';
import AuditTestDetails from './AuditTestDetails';
import { supabase } from '../supabaseClient';
import { API_URL } from '../globals';
import { useNavigate, useSearchParams } from "react-router-dom";

const AuditDetailPage = ({ id }) => {
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [audit, setAudit] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCache, setPageCache] = useState(new Map());
  const [initiatingAudit, setInitiatingAudit] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const itemsPerPage = 10;
  
  // Function to fetch tasks for a specific page
  const fetchTasksForPage = useCallback(async (pageNum, authToken) => {
    const searchParam = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : '';
    const headers = {
      'Content-Type': 'application/json'
    };
    
    // Only add auth token if provided
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }
    
    const tasksResponse = await fetch(
      `${API_URL}/audit/get_scan_tasks?scan_id=${id}&page=${pageNum}&page_size=${itemsPerPage}${searchParam}`, {
      method: 'GET',
      headers: headers
    });

    if (!tasksResponse.ok) {
      throw new Error(`Failed to fetch tasks: ${tasksResponse.status} ${tasksResponse.statusText}`);
    }

    return await tasksResponse.json();
  }, [id, searchTerm, itemsPerPage]);

  // Function to preload pages - Remove pageCache from dependencies
  const preloadPages = useCallback(async (currentPage, authToken) => {
    const pagesToPreload = new Set();
    // Add 2 pages before and 2 pages after current page
    for (let i = -2; i <= 2; i++) {
      const pageToLoad = currentPage + i;
      if (pageToLoad > 0 && pageToLoad <= totalPages && !pageCache.has(pageToLoad)) {
        pagesToPreload.add(pageToLoad);
      }
    }

    // Fetch all pages in parallel
    const preloadPromises = Array.from(pagesToPreload).map(async (pageNum) => {
      try {
        const data = await fetchTasksForPage(pageNum, authToken);
        // Use functional update to avoid dependency on pageCache
        setPageCache(prevCache => {
          const newCache = new Map(prevCache);
          newCache.set(pageNum, data);
          return newCache;
        });
      } catch (error) {
        console.error(`Error preloading page ${pageNum}:`, error);
      }
    });

    await Promise.all(preloadPromises);
  }, [fetchTasksForPage, totalPages]);

  // Function to fetch audit details
  const fetchAuditDetails = useCallback(async () => {
    try {
      const { data: authData, error: authError } = await supabase.auth.getSession();
      
      // Set up headers without auth token by default
      const headers = {
        'Content-Type': 'application/json'
      };
      
      // Add auth token if available
      if (!authError && authData?.session?.access_token) {
        headers['Authorization'] = `Bearer ${authData.session.access_token}`;
      }
      
      const auditResponse = await fetch(`${API_URL}/audit/get_scan?scan_id=${id}`, {
        method: 'GET',
        headers: headers
      });

      if (!auditResponse.ok) {
        throw new Error(`Failed to fetch audit: ${auditResponse.status} ${auditResponse.statusText}`);
      }

      const auditData = await auditResponse.json();
      setAudit(auditData);

      // Only fetch tasks if audit is not in PENDING state
      if (auditData.status !== 'PENDING') {
        // Pass token if available (might be null)
        const accessToken = authData?.session?.access_token || null;
        const tasksData = await fetchTasksForPage(1, accessToken);
        setTasks(tasksData.tasks);
        setTotalTasks(tasksData.total);
        setTotalPages(tasksData.total_pages);
        setPageCache(new Map([[1, tasksData]]));
        await preloadPages(1, accessToken);
      }

      return auditData;
    } catch (error) {
      console.error('Error fetching audit details:', error);
      setAudit(null);
      setTasks([]);
      return null;
    }
  }, [id, fetchTasksForPage, preloadPages]);

  // Fetch audit data - initial load
  useEffect(() => {
    const loadInitialData = async () => {
      if (!id) return;
      
      setLoading(true);
      try {
        await fetchAuditDetails();
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, [id, fetchAuditDetails]);

  // Handle page changes - debounce preloading
  useEffect(() => {
    let isCancelled = false;
    
    const loadPage = async () => {
      // Don't do anything if we're still loading initially
      if (loading) return;

      setTasksLoading(true);
      try {
        const { data: authData } = await supabase.auth.getSession();
        // Get token if available (might be null)
        const accessToken = authData?.session?.access_token || null;

        // Check if page is in cache
        if (pageCache.has(page)) {
          const cachedData = pageCache.get(page);
          setTasks(cachedData.tasks);
          setTotalTasks(cachedData.total);
          setTotalPages(cachedData.total_pages);
        } else {
          // Fetch current page if not in cache
          const tasksData = await fetchTasksForPage(page, accessToken);
          if (isCancelled) return;
          
          setTasks(tasksData.tasks);
          setTotalTasks(tasksData.total);
          setTotalPages(tasksData.total_pages);
          setPageCache(prevCache => {
            const newCache = new Map(prevCache);
            newCache.set(page, tasksData);
            return newCache;
          });
        }

        // Add a small delay before preloading to prevent rapid successive calls
        if (!isCancelled) {
          setTimeout(() => {
            if (!isCancelled) {
              preloadPages(page, accessToken);
            }
          }, 500);
        }
      } catch (error) {
        console.error('Error loading page:', error);
      } finally {
        if (!isCancelled) {
          setTasksLoading(false);
        }
      }
    };

    loadPage();

    return () => {
      isCancelled = true;
    };
  }, [page, loading, fetchTasksForPage, preloadPages]);

  // If still loading, show loading indicator
  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%', 
        width: '100%',
        p: 4 
      }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  // If the audit wasn't found, show an error message
  if (!audit) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%',
        flexDirection: 'column',
        gap: 2
      }}>
        <AlertCircle size={48} color="#AAAAAA" />
        <Typography variant="h5">Audit Not Found</Typography>
        <Typography variant="body1" color="text.secondary">
          The requested audit (ID: {id}) could not be found.
        </Typography>
      </Box>
    );
  }

  // Calculate metrics
  const totalTests = (audit.pass_test_count || 0) + (audit.fail_test_count || 0);
  const progress = audit.total_testable_nodes && audit.completed_nodes ? ((audit.completed_nodes || 0) / audit.total_testable_nodes) * 100 : 0;
  const passRate = totalTests > 0 ? ((audit.pass_test_count || 0) / totalTests) * 100 : 0;
  const testCoverage = audit.total_nodes && audit.total_testable_nodes ? (audit.total_testable_nodes / audit.total_nodes) * 100 : 0;

  // Handle selecting an entity
  const handleEntitySelect = (entity) => {
    navigate(`/audit?id=${id}&task_id=${entity.id}`);
  };

  // Get task_id from URL if it exists
  const taskId = searchParams.get('task_id');
  
  // If a task is selected and we have the taskId, show its details
  if (taskId) {
    // Get the auth token if available
    const { data: authData } = supabase.auth.getSession();
    const accessToken = authData?.session?.access_token || null;
    
    return <AuditTestDetails 
      scanId={id}
      taskId={taskId}
      accessToken={accessToken}
      onBack={() => {
        navigate(`/audit?id=${id}`);
      }}
    />;
  }
  
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Helper function to get status icon
  const getStatusIcon = (stage) => {
    switch(stage) {
      case 'DONE':
        return <CheckCircle size={16} color="#FFFFFF" />;
      case 'FAILED':
        return <XCircle size={16} color="#FFFFFF" />;
      case 'IN_PROGRESS':
        return <Hourglass size={16} color="#FFFFFF" />;
      default:
        return <AlertCircle size={16} color="#FFFFFF" />;
    }
  };
  
  // Helper function to get status background
  const getStatusBackground = (stage) => {
    switch(stage) {
      case 'DONE':
        return alpha('#4CAF50', 0.15);
      case 'FAILED':
        return alpha('#F44336', 0.15);
      case 'IN_PROGRESS':
        return alpha('#FFFFFF', 0.05);
      default:
        return alpha('#AAAAAA', 0.15);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ color: 'text.primary', fontWeight: 300, mb: 2 }}>
          Audit #{audit.id}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          Created on {new Date(audit.created_at).toLocaleDateString()}
        </Typography>
      </Box>
      
      {/* Statistics Boxes */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            bgcolor: alpha('#FFFFFF', 0.03),
            transition: 'all 0.2s',
            '&:hover': {
              bgcolor: alpha('#FFFFFF', 0.05),
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Total Tests
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 400, mb: 1 }}>
              {audit.status === 'PENDING' ? '-' : (totalTests || '-')}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Total test cases in audit
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            bgcolor: alpha('#FFFFFF', 0.03),
            transition: 'all 0.2s',
            '&:hover': {
              bgcolor: alpha('#FFFFFF', 0.05),
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Bugs Found
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 400, mb: 1 }}>
              {audit.status === 'PENDING' ? '-' : (audit.fail_test_count || '-')}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Number of failed tests
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            bgcolor: alpha('#FFFFFF', 0.03),
            transition: 'all 0.2s',
            '&:hover': {
              bgcolor: alpha('#FFFFFF', 0.05),
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Coverage
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 400, mb: 1 }}>
              {audit.status === 'PENDING' ? '-' : (testCoverage === 0 ? '-' : `${testCoverage.toFixed(1)}%`)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Test coverage percentage
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            bgcolor: alpha('#FFFFFF', 0.03),
            transition: 'all 0.2s',
            '&:hover': {
              bgcolor: alpha('#FFFFFF', 0.05),
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Pass Rate
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 400, mb: 1 }}>
              {audit.status === 'PENDING' ? '-' : (passRate === 0 ? '-' : `${passRate.toFixed(1)}%`)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Test pass rate
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Initiate Audit Button - Only show when status is PENDING */}
      {audit.status === "PENDING" && (
        <>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              mb: 3,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -24,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 2,
                height: 24,
                background: `linear-gradient(${alpha('#FFFFFF', 0.2)}, ${alpha('#FFFFFF', 0.05)})`
              }
            }}
          >
            <Paper
              sx={{
                p: 3,
                bgcolor: alpha('#FFFFFF', 0.03),
                borderRadius: 2,
                maxWidth: 600,
                border: `1px solid ${alpha('#FFFFFF', 0.1)}`,
                position: 'relative'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <AlertCircle size={24} color="#AAAAAA" style={{ flexShrink: 0, marginTop: 2 }} />
                <Box>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 400 }}>
                    Ready to Begin Audit
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                    Click the button below to initiate the audit process. Please note:
                  </Typography>
                  <Box component="ul" sx={{ 
                    m: 0, 
                    pl: 2,
                    '& li': { 
                      color: 'text.secondary',
                      mb: 1,
                      '&:last-child': { mb: 0 }
                    }
                  }}>
                    <li>The audit process may take several hours or even days to complete</li>
                    <li>You'll receive an email notification when the audit is fully complete</li>
                    <li>You can track the progress in real-time on this page</li>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <ArrowDown 
              size={24} 
              color="#AAAAAA" 
              style={{ 
                marginTop: 36,
                marginBottom: 12,
                animation: 'bounce 2s infinite'
              }}
            />
          </Box>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mb: 4,
            '@keyframes bounce': {
              '0%, 100%': {
                transform: 'translateY(0)'
              },
              '50%': {
                transform: 'translateY(-10px)'
              }
            }
          }}>
            <Button
              variant="contained"
              size="large"
              startIcon={initiatingAudit ? <CircularProgress size={20} color="inherit" /> : <Play size={20} />}
              disabled={initiatingAudit}
              onClick={async () => {
                try {
                  setInitiatingAudit(true);
                  const { data: authData, error: authError } = await supabase.auth.getSession();
                  if (authError) throw authError;

                  // Start the audit
                  const response = await fetch(`${API_URL}/audit/scan`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${authData.session.access_token}`
                    },
                    body: JSON.stringify({ scan_id: id })
                  });

                  if (response.status === 402) {
                    const data = await response.json();
                    if (data.payment_link) {
                      window.location.href = data.payment_link;
                      return;
                    }
                  }

                  if (!response.ok) {
                    throw new Error(`Failed to start audit: ${response.status} ${response.statusText}`);
                  }

                  // Re-fetch audit details to get updated status
                  await fetchAuditDetails();
                } catch (error) {
                  console.error('Error starting audit:', error);
                  // You might want to show an error message to the user here
                } finally {
                  setInitiatingAudit(false);
                }
              }}
              sx={{
                bgcolor: alpha('#FFFFFF', 0.08),
                color: 'white',
                px: 4,
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1.1rem',
                fontWeight: 400,
                letterSpacing: '0.01em',
                border: `1px solid ${alpha('#FFFFFF', 0.1)}`,
                backdropFilter: 'blur(8px)',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bgcolor: alpha('#FFFFFF', 0.12),
                  border: `1px solid ${alpha('#FFFFFF', 0.15)}`,
                  transform: 'translateY(-2px)',
                  boxShadow: `0 4px 20px ${alpha('#000000', 0.3)}`
                },
                '&:active': {
                  transform: 'translateY(0)',
                  boxShadow: `0 2px 10px ${alpha('#000000', 0.2)}`
                },
                '&:disabled': {
                  bgcolor: alpha('#FFFFFF', 0.04),
                  color: alpha('#FFFFFF', 0.3),
                  border: `1px solid ${alpha('#FFFFFF', 0.05)}`,
                  transform: 'none',
                  boxShadow: 'none'
                }
              }}
            >
              {initiatingAudit ? 'Starting Audit...' : 'Initiate Audit'}
            </Button>
          </Box>
        </>
      )}

      {/* Progress Bar - Only show when status is IN_PROGRESS */}
      {audit.status === "IN_PROGRESS" && (
        <Box sx={{ mb: 4 }}>
          <Paper sx={{ 
            p: 3, 
            bgcolor: alpha('#FFFFFF', 0.03),
            transition: 'all 0.2s',
            '&:hover': {
              bgcolor: alpha('#FFFFFF', 0.05),
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Overall Progress
              </Typography>
              <Typography variant="body2" color="text.primary">
                {progress.toFixed(1)}%
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              sx={{ 
                height: 8, 
                borderRadius: 4,
                bgcolor: alpha('#FFFFFF', 0.05),
                '& .MuiLinearProgress-bar': {
                  background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 100%)',
                  borderRadius: 4
                }
              }} 
            />
          </Paper>
        </Box>
      )}
      
      {/* Entity List - Only show when status is not PENDING */}
      {audit.status !== "PENDING" && (
        <Paper sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 3 
          }}>
            <Typography variant="h5" sx={{ fontWeight: 300 }}>
              Tests {totalTasks > 0 && `(${totalTasks})`}
            </Typography>
            
            <TextField
              size="small"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={18} color="#AAAAAA" />
                  </InputAdornment>
                ),
              }}
              sx={{ 
                width: { xs: '100%', sm: '250px' },
                '& .MuiOutlinedInput-root': {
                  bgcolor: alpha('#FFFFFF', 0.02),
                  '&:hover': {
                    bgcolor: alpha('#FFFFFF', 0.04),
                  }
                }
              }}
            />
          </Box>
          
          <Box sx={{ position: 'relative' }}>
            {tasksLoading && (
              <Box sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                height: 2
              }}>
                <LinearProgress />
              </Box>
            )}
            
            {tasks.length === 0 ? (
              <Box sx={{ textAlign: 'center', p: 4 }}>
                <Typography color="text.secondary">No tasks found</Typography>
              </Box>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {tasks.map((task) => (
                    <Paper 
                      key={task.task_id}
                      onClick={() => handleEntitySelect({ id: task.task_id, title: task.title })}
                      sx={{ 
                        p: 2,
                        bgcolor: alpha('#FFFFFF', 0.02),
                        transition: 'all 0.2s',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: alpha('#FFFFFF', 0.04),
                          transform: 'translateX(2px)',
                        }
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        {/* Task Title */}
                        <Grid item xs={12} sm={task.stage === 'IN_PROGRESS' ? 8 : 9}>
                          <Typography variant="body1" sx={{ fontWeight: 400, letterSpacing: '0.01em' }}>
                            {task.title}
                          </Typography>
                        </Grid>
                        
                        {/* Status - Show progress for IN_PROGRESS, show pass/fail chips for DONE */}
                        <Grid item xs={12} sm={3} sx={{ 
                          display: 'flex', 
                          justifyContent: 'flex-end', 
                          gap: 1,
                          ml: 'auto'
                        }}>
                          {task.stage === 'IN_PROGRESS' ? (
                            <Box sx={{ 
                              display: 'inline-flex', 
                              alignItems: 'center', 
                              gap: 1,
                              py: 0.5,
                              px: 1.5,
                              borderRadius: 4,
                              bgcolor: alpha('#FFFFFF', 0.05),
                              ml: 'auto'
                            }}>
                              <Hourglass size={16} color="#FFFFFF" />
                              <Typography variant="body2" sx={{ fontWeight: 300, color: 'text.primary' }}>
                                In progress...
                              </Typography>
                            </Box>
                          ) : task.stage === 'DONE' && task.scan_summary?.test_cases ? (
                            <>
                              {/* Pass count chip */}
                              {task.scan_summary.test_cases.filter(test => test.verdict === 'PASS').length > 0 && (
                                <Box sx={{ 
                                  display: 'inline-flex', 
                                  alignItems: 'center', 
                                  gap: 0.5,
                                  py: 0.5,
                                  px: 1.5,
                                  borderRadius: 4,
                                  bgcolor: alpha('#4CAF50', 0.15)
                                }}>
                                  <CheckCircle size={14} color="#4CAF50" />
                                  <Typography variant="body2" sx={{ color: '#4CAF50' }}>
                                    {task.scan_summary.test_cases.filter(test => test.verdict === 'PASS').length}
                                  </Typography>
                                </Box>
                              )}
                              
                              {/* Fail count chip */}
                              {task.scan_summary.test_cases.filter(test => test.verdict === 'FAIL').length > 0 && (
                                <Box sx={{ 
                                  display: 'inline-flex', 
                                  alignItems: 'center', 
                                  gap: 0.5,
                                  py: 0.5,
                                  px: 1.5,
                                  borderRadius: 4,
                                  bgcolor: alpha('#F44336', 0.15)
                                }}>
                                  <XCircle size={14} color="#F44336" />
                                  <Typography variant="body2" sx={{ color: '#F44336' }}>
                                    {task.scan_summary.test_cases.filter(test => test.verdict === 'FAIL').length}
                                  </Typography>
                                </Box>
                              )}
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Box>
                
                {totalPages > 1 && (
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mt: 3,
                    '& .MuiPagination-ul': {
                      '& .MuiPaginationItem-root': {
                        color: 'text.secondary',
                        '&.Mui-selected': {
                          bgcolor: alpha('#FFFFFF', 0.1),
                          '&:hover': {
                            bgcolor: alpha('#FFFFFF', 0.2),
                          }
                        },
                        '&:hover': {
                          bgcolor: alpha('#FFFFFF', 0.05),
                        }
                      }
                    }
                  }}>
                    <Pagination 
                      count={totalPages} 
                      page={page} 
                      onChange={handlePageChange}
                      color="primary"
                      size="large"
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default AuditDetailPage;
