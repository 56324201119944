import React from 'react';
import { Box, Typography, alpha, Paper } from '@mui/material';
import { FileText, GitGraph, Cpu, Bug, Clock } from 'lucide-react';

const ProcessStep = ({ icon: Icon, title, description }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      bgcolor: alpha('#FFFFFF', 0.03),
      borderRadius: 2,
      display: 'flex',
      gap: 2,
      alignItems: 'flex-start',
      width: '100%'
    }}
  >
    <Box sx={{ 
      p: 1.5, 
      borderRadius: 1.5, 
      bgcolor: alpha('#FFFFFF', 0.05),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Icon size={24} style={{ color: '#AAA' }} />
    </Box>
    <Box>
      <Typography variant="h6" sx={{ mb: 1, fontWeight: 500, color: 'text.primary' }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  </Paper>
);

const AuditGuide = () => {
  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex',
      flexDirection: 'column',
      p: 4,
      gap: 4,
      maxWidth: '800px',
      mx: 'auto'
    }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h4" sx={{ mb: 2, fontWeight: 300, color: 'text.primary' }}>
          Introducing Fume Audits!
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ maxWidth: '600px', mx: 'auto' }}>
          Discover how our advanced audit system analyzes your codebase to uncover security vulnerabilities, 
          bugs, and potential improvements through a sophisticated multi-agent approach.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <ProcessStep
          icon={GitGraph}
          title="Code Transformed into Intelligent Graph"
          description="Your codebase is transformed into a comprehensive graph structure, mapping all relationships and dependencies between different components, functions, and modules."
        />

        <ProcessStep
          icon={Cpu}
          title="AI Agents Deployed to Every Code Entity"
          description="A dedicated Fume agent is spawned for each node in the code graph. These agents work independently but maintain awareness of the entire codebase context."
        />

        <ProcessStep
          icon={Bug}
          title="Thousands of Custom Tests"
          description="Agents collectively perform hundreds or thousands of custom tests, examining every aspect of your code for potential issues, from security vulnerabilities to optimization opportunities."
        />

        <ProcessStep
          icon={Clock}
          title="Deep Analysis Worth the Wait"
          description="Due to the comprehensive nature of our analysis, audits may take several hours or even days to complete, ensuring no stone is left unturned in securing and improving your codebase."
        />
      </Box>
    </Box>
  );
};

export default AuditGuide; 