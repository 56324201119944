import React from 'react';
import { Box, Typography, Paper, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MessageSquare, Clock, CheckCircle, Loader2 } from 'lucide-react';


const TaskHistory = ({ taskHistory }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const userTimezoneOffset = now.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() + userTimezoneOffset);
    
    const diffTime = Math.abs(now - localDate);
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);
    
    if (diffMinutes < 60) {
      return diffMinutes === 0 ? 'Just now' : `${diffMinutes}m ago`;
    } else if (diffHours < 24) {
      return `${diffHours}h ago`;
    } else if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday'; 
    } else if (diffDays < 7) {
      return `${diffDays}d ago`;
    } else if (diffWeeks < 4) {
      return `${diffWeeks}w ago`;
    } else if (diffMonths < 12) {
      return `${diffMonths}mo ago`;
    } else {
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric', 
        year: date.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
      });
    }
  };

  const getStatusDetails = (status) => {
    switch (status) {
      case 'in_queue':
        return {
          title: 'In Queue',
          icon: Clock,
          color: '#888'
        };
      case 'in_progress':
        return {
          title: 'In Progress',
          icon: Loader2,
          color: '#2d4b73'
        };
      case 'waiting_response':
        return {
          title: 'Waiting Response',
          icon: MessageSquare,
          color: '#735c2d'
        };
      case 'done':
        return {
          title: 'Done',
          icon: CheckCircle,
          color: '#2e4e41'
        };
      default:
        return {
          title: 'Unknown',
          icon: MessageSquare,
          color: '#383838'
        };
    }
  };

  const groupChatsByStatus = (chats) => {
    return {
      in_queue: chats.filter(chat => chat.stage === 'IN_QUEUE'),
      in_progress: chats.filter(chat => chat.stage === 'IN_PROGRESS'),
      waiting_response: chats.filter(chat => chat.stage === 'WAITING_USER_RESPONSE'),
      done: chats.filter(chat => chat.stage === 'DONE' || !chat.stage)
    };
  };

  const groupedChats = groupChatsByStatus(taskHistory);
  const columns = ['in_queue', 'in_progress', 'waiting_response', 'done'];

  const getSourceColor = (source) => {
    switch (source?.toLowerCase()) {
      case 'web':
        return '#2e4e41';
      case 'linear':
        return '#5E6AD2';
      case 'jira':
        return '#2d4b73';
      case 'github':
        return '#6e5494';
      case 'gitlab':
        return '#B25835';
      default:
        return '#383838';
    }
  };

  const ChatCard = ({ chat: task }) => {
    const formattedDate = formatDate(task.created_at);
    const source = task.source || 'web';
    const studioName = task.studio?.display_name || 'No Studio';

    return (
      <Box
        onClick={() => navigate(`/chat/${task.task_id}`)}
        sx={{
          p: 2,
          mb: 1,
          backgroundColor: 'transparent',
          cursor: 'pointer',
          border: '1px solid #2a2a2a',
          borderRadius: 1,
          transition: 'all 0.2s',
          '&:hover': {
            backgroundColor: '#1C1C1C',
            transform: 'translateY(-2px)',
            borderColor: '#333',
          },
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          mb: 1.5 
        }}>
          <Typography
            sx={{
              color: '#fff',
              fontSize: '0.875rem',
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flexGrow: 1,
              mr: 2
            }}
          >
            {task.title || 'Untitled Chat'}
          </Typography>
          <Typography
            sx={{
              color: '#888',
              fontSize: '0.75rem',
              flexShrink: 0
            }}
          >
            {formattedDate}
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          mb: task.last_message ? 1.5 : 0
        }}>
          <Chip
            label={source}
            size="small"
            sx={{
              backgroundColor: getSourceColor(source),
              color: '#e0e0e0',
              height: '20px',
              border: '1px solid #444',
              '& .MuiChip-label': {
                fontSize: '0.675rem',
                px: 1,
                fontWeight: 500
              }
            }}
          />
          <Chip
            label={studioName}
            size="small"
            sx={{
              backgroundColor: '#242424',
              color: '#e0e0e0',
              height: '20px',
              border: '1px solid #444',
              maxWidth: '150px',
              '& .MuiChip-label': {
                fontSize: '0.675rem',
                px: 1,
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }}
          />
        </Box>

        {task.last_message && (
          <Typography
            sx={{
              color: '#888',
              fontSize: '0.75rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {task.last_message}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 2,
        overflowX: 'auto',
        minHeight: 200,
        pb: 2
      }}
    >
      {columns.map(status => {
        const statusDetails = getStatusDetails(status);
        const StatusIcon = statusDetails.icon;
        
        return (
          <Box key={status} sx={{ minWidth: 250 }}>
            <Box 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 2,
                p: 1,
                borderBottom: '1px solid #333'
              }}
            >
              <StatusIcon size={16} style={{ color: statusDetails.color }} />
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '0.875rem',
                  fontWeight: 600
                }}
              >
                {statusDetails.title}
                <Typography 
                  component="span"
                  sx={{ 
                    ml: 1,
                    color: '#888',
                    fontSize: '0.75rem'
                  }}
                >
                  {groupedChats[status].length}
                </Typography>
              </Typography>
            </Box>
            
            <Box sx={{ px: 1 }}>
              {groupedChats[status].map(chat => (
                <ChatCard key={chat.id} chat={chat} />
              ))}
              
              {groupedChats[status].length === 0 && (
                <Typography
                  sx={{
                    color: '#888',
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    py: 2
                  }}
                >
                  No tasks
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default TaskHistory;