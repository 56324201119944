import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Box,
  IconButton,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from '@mui/icons-material/Menu';
import { PanelRightClose, PanelRightOpen, LayoutPanelLeft, Unplug, Laptop, User, BookOpenText, ScanSearch } from 'lucide-react';
import { useSidebar } from '../contexts/SidebarContext';
import { useDataSource } from '../contexts/DataSourceContext';
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL } from "../globals";

export default function NavBar({
  user,
  taskHistory,
  lastVisitedChat,
  pos,
}) {
  const { isOpen, setIsOpen, isHovered, handleMouseEnter, handleMouseLeave } = useSidebar();
  const navigate = useNavigate();
  const location = useLocation();
  const isStudioPage = location.pathname.startsWith('/studio/');
  const { openDataSourceModal, setLoading } = useDataSource();
  const [options, setOptions] = useState([]);

  // Add this helper function to determine if a path is active
  const isActivePath = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  // Add common styles for list items
  const getListItemStyles = (path) => ({
    justifyContent: isOpen || isHovered ? 'start' : 'center',
    px: isOpen || isHovered ? 2 : 0,
    backgroundColor: isActivePath(path) ? 'rgba(255, 255, 255, 0.08)' : 'transparent',
    '&:hover': {
      backgroundColor: isActivePath(path) ? 'rgba(255, 255, 255, 0.12)' : 'rgba(255, 255, 255, 0.08)',
    },
    '& .MuiListItemText-primary': { 
      color: '#fff',
      fontWeight: isActivePath(path) ? 500 : 400,
    },
    borderRadius: '6px',
    mb: 0.5,
    cursor: 'pointer',
  });

  // Add common styles for list item icons
  const getListItemIconStyles = (path) => ({
    minWidth: isOpen || isHovered ? 56 : 'auto',
    mr: isOpen || isHovered ? 'auto' : 0,
    color: isActivePath(path) ? '#fff' : 'rgba(255, 255, 255, 0.7)',
  });

  const handleNewTask = async () => {
    const onSelectCallback = async (selectedOptionValue, selectedOption) => {
      try {
        const authToken = (await supabase.auth.getSession()).data.session.access_token;
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const response = await axios.post(`${API_URL}/start_task`, null, {
          headers: headers,
        });

        if (response.data?.task_id) {
          if (selectedOption) {
            await axios.put(
              `${API_URL}/studio/select`,
              {
                task_id: response.data.task_id,
                studio_id: selectedOption.value,
              },
              { headers }
            );
          }
          
          navigate(`/chat/${response.data.task_id}`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Show modal immediately with loading state
    openDataSourceModal([], onSelectCallback);
    setLoading(true);

    // Load studios asynchronously
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const studiosResponse = await axios.get(`${API_URL}/studio/seeds`, {
        headers: headers,
      });

      if (studiosResponse.data) {
        const studios = studiosResponse.data.filter(studio => studio.status === "READY" || studio.status === "BUSY");
        const studioOptions = studios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));
        
        // Update modal with loaded options, keeping the original callback
        openDataSourceModal(studioOptions, onSelectCallback, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Drawer
        variant="permanent"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: isOpen || isHovered ? 240 : 64,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isOpen || isHovered ? 240 : 64,
            boxSizing: "border-box",
            transition: 'width 0.2s',
            overflowX: 'hidden',
            backgroundColor: '#121212',
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
          },
        }}
        open
      >
        <Box sx={{ 
          height: 56, 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: isOpen || isHovered ? 'flex-start' : 'center',
          pl: isOpen || isHovered ? 1 : 0,
          width: '100%',
          mb: 3
        }}>
          <IconButton 
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              m: 0,
              p: '8px',
              color: '#fff',
            }}
          >
            {isOpen || isHovered ? <PanelRightOpen size={20} /> : <PanelRightClose size={20} />}
          </IconButton>
        </Box>

        <List sx={{ flexGrow: 1, px: 1 }}>
          {!isStudioPage && (
            <ListItem onClick={handleNewTask} sx={getListItemStyles('/new')}>
              <ListItemIcon sx={getListItemIconStyles('/new')}>
                <AddIcon />
              </ListItemIcon>
              {(isOpen || isHovered) && <ListItemText primary="Start New" />}
            </ListItem>
          )}
          <ListItem onClick={() => navigate("/")} sx={getListItemStyles('/')}>
            <ListItemIcon sx={getListItemIconStyles('/')}>
              <LayoutPanelLeft size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Home" />}
          </ListItem>
          <ListItem onClick={() => navigate("/studios")} sx={getListItemStyles('/studios')}>
            <ListItemIcon sx={getListItemIconStyles('/studios')}>
              <Laptop size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Studios" />}
          </ListItem>
          <ListItem onClick={() => navigate("/playbooks")} sx={getListItemStyles('/playbooks')}>
            <ListItemIcon sx={getListItemIconStyles('/playbooks')}>
              <BookOpenText size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Playbooks" />}
          </ListItem>
          <ListItem onClick={() => navigate("/integrations")} sx={getListItemStyles('/integrations')}>
            <ListItemIcon sx={getListItemIconStyles('/integrations')}>
              <Unplug size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Integrations" />}
          </ListItem>
          <ListItem onClick={() => navigate("/audit")} sx={getListItemStyles('/audit')}>
            <ListItemIcon sx={getListItemIconStyles('/audit')}>
              <ScanSearch size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && (
              <>
                <ListItemText primary="Audit" />
                <Chip 
                  label="NEW" 
                  size="small" 
                  sx={{ 
                    height: 20, 
                    fontSize: '0.7rem', 
                    backgroundColor: 'rgba(255, 255, 255, 1)', 
                    color: 'black',
                    ml: 1 
                  }} 
                />
              </>
            )}
          </ListItem>
        </List>

        <Box sx={{ mt: "auto", borderTop: "1px solid rgba(255, 255, 255, 0.12)", p: 1 }}>
          <ListItem 
            onClick={() => navigate("/profile")} 
            sx={getListItemStyles('/profile')}
          >
            <ListItemIcon sx={{ minWidth: isOpen || isHovered ? 56 : 'auto' }}>
              <Avatar 
                sx={{ 
                  bgcolor: colors.teal[200],
                  width: 32,
                  height: 32,
                  color: '#000'
                }}
              >
                {user?.full_name &&
                  user?.full_name
                    .split(" ")
                    .slice(0, 2)
                    .map((name) => name[0])}
              </Avatar>
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary={user?.full_name} />}
          </ListItem>
        </Box>
      </Drawer>
    </>
  );
}