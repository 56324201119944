import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useParams } from "react-router-dom";
import FileExplorer from "./components/FileExplorer";
import CodeEditor from "./components/CodeEditor";
import { Box, Button, CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TerminalWindow from "./components/Terminal";
import { API_URL } from "../../globals";
import { supabase } from "../../supabaseClient";
import Loading from "../../pages/Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { useSidebar } from '../../contexts/SidebarContext';
import Browser from "./components/Browser";
import ActionCards from './components/ActionCards';

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#121212',
    },
    primary: {
      main: "#424242",
    },
  },
});

const Studio = forwardRef(({ studioId: propStudioId, editMode, screenshotUrl, currentUrl }, ref) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const studioId = propStudioId || id; // Use studioId from prop or fallback to the one from URL params
  const [vnc_domain, setVncDomain] = useState("");
  const [studioData, setStudioData] = useState({});
  const [activeFile, setActiveFile] = useState("");
  const [fumeTerminalContent, setFumeTerminalContent] = useState("");
  const [refresh, setRefresh] = useState(0);
  const { isOpen } = useSidebar();
  const [isVncConnected, setIsVncConnected] = useState(false);
  const [activeRecording, setActiveRecording] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useImperativeHandle(ref, () => ({
    updateStudiData(message) {
      if (
        message.type === "file_edit" ||
        message.type === "create_file" ||
        message.type === "get_definition" ||
        message.type === "get_file_content"
      ) {
        setActiveFile(message.data.file_path);
      } else if (
        message.type === "look_on_process" ||
        message.type === "run_command"
      ) {
        setFumeTerminalContent(fumeTerminalContent + message.data.output);
      }
    },
  }));

  const triggerRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const saveStudioHandler = async () => {
    setIsSaving(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const headers = { Authorization: `Bearer ${authToken}` };
      const body = {
        studio_id: studioId,
      };
      const response = await axios.post(`${API_URL}/studio/save-studio`, body, {
        headers: headers,
      });
      navigate(`/studios`);
    } catch (error) {
      console.error('Error saving studio:', error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const fetchVncInfo = async () => {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await fetch(
        `${API_URL}/studio/info?studio_id=${studioId}`,
        {
          headers: headers,
        }
      );
      const res_data = await response.json();
      const instance_name = res_data.instance_name;
      console.log(res_data);
      var vnc_domain = res_data.vnc_domain;
      console.log(vnc_domain);
      if (!vnc_domain) {
        vnc_domain = `vnc.${instance_name}.fumedev.com`;
      }
      setVncDomain(vnc_domain);
      setStudioData(res_data);
    };
    fetchVncInfo();
  }, []);

  useEffect(() => {
    const checkActiveRecording = async () => {
      try {
        const authToken = (await supabase.auth.getSession()).data.session.access_token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await axios.get(
          `${API_URL}/studio/check_active_recording?studio_id=${studioId}`,
          { headers }
        );
        
        if (response.data.has_active_recording) {
          const newRecording = response.data.recording;
          const currentActions = activeRecording?.actions || [];
          const newActions = newRecording.actions || [];
          
          if (!activeRecording || currentActions.length !== newActions.length) {
            setActiveRecording(newRecording);
          }
        } else {
          setActiveRecording(null);
        }
      } catch (error) {
        console.error("Error checking active recording:", error);
      }
    };

    checkActiveRecording();

    const pollInterval = setInterval(
      checkActiveRecording, 
      activeRecording ? 1000 : 10000
    );

    return () => clearInterval(pollInterval);
  }, [studioId, activeRecording]);

  const handleFileChange = (fileName) => {
    setActiveFile(fileName);
  };

  if (studioData.status !== "READY" && studioData.status !== "BUSY") {
    return Loading;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar
        user={studioData.user}
        startTask={null}
        pos="left"
      />
      <Box
        sx={{
          backgroundColor: '#121212',
          height: !editMode ? 'calc(100vh - 80px)' : '100vh', // Adjusted to account for header and bottom padding
          display: "flex",
          position: "fixed",
          left: isOpen ? '240px' : '64px',
          right: 0,
          top: !editMode ? '64px' : '0',
          bottom: 0, // Removed bottom padding here since we're handling it in height
          transition: 'left 0.2s',
        }}
      >
        <PanelGroup 
          direction="horizontal" 
          style={{ 
            width: '100%',
            backgroundColor: '#121212',
          }}
        >
          <Panel
            defaultSize={15}
            maxSize={25}
            style={{
              height: "100vh",
              color: "#d4d4d4",
              backgroundColor: '#121212',
              borderRight: "1px solid rgba(255, 255, 255, 0.12)",
            }}
          >
            <Box
              sx={{
                height: "95vh",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: '#121212',
              }}
            >
              <FileExplorer
                studioId={studioId}
                changeEditor={handleFileChange}
                refresh={refresh}
              />
              {editMode && (
                <Button
                  style={{
                    marginTop: "auto",
                    width: "100%",
                  }}
                  onClick={saveStudioHandler}
                  variant="outlined"
                  disabled={isSaving}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    borderColor: '#555',
                    color: '#fff',
                    '&:hover': {
                      borderColor: '#777',
                      backgroundColor: '#1e1e1e',
                    },
                  }}
                >
                  {isSaving ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Save Studio'
                  )}
                </Button>
              )}
            </Box>
          </Panel>
          
          <PanelResizeHandle style={{
            width: '1px',
            background: 'rgba(255, 255, 255, 0.12)',
          }} />
          
          <Panel
            defaultSize={45}
            style={{
              height: "100vh",
              backgroundColor: '#121212',
              borderRight: "1px solid rgba(255, 255, 255, 0.12)",
            }}
          >
            <Box
              sx={{
                height: "95vh",
                padding: "16px",
                color: "white",
                backgroundColor: '#121212',
              }}
            >
              <CodeEditor
                fileName={activeFile}
                studioId={studioId}
              />
            </Box>
          </Panel>
          
          <PanelResizeHandle style={{
            width: '1px',
            background: 'rgba(255, 255, 255, 0.12)',
          }} />
          
          <Panel style={{ 
            height: "100vh", 
            backgroundColor: '#121212',
          }}>
            <PanelGroup direction="vertical">
              <Panel
                defaultSize={55}
                style={{
                  backgroundColor: '#121212',
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Browser 
                  studioData={studioData} 
                  vnc_domain={vnc_domain} 
                  screenshotUrl={screenshotUrl}
                  currentUrl={currentUrl}
                />
              </Panel>

              <PanelResizeHandle style={{
                height: '1px',
                background: 'rgba(255, 255, 255, 0.12)',
              }} />

              <Panel defaultSize={45} style={{
                backgroundColor: '#121212',
              }}>
                <Box sx={{ 
                  height: "90%", 
                  padding: "16px",
                  backgroundColor: '#121212',
                }}>
                  {activeRecording ? (
                    <ActionCards 
                      actions={activeRecording.actions} 
                      recordingId={activeRecording._id} 
                    />
                  ) : (
                    <TerminalWindow
                      studioId={studioId}
                      refreshStudio={triggerRefresh}
                      fumeTerminalContent={fumeTerminalContent}
                    />
                  )}
                </Box>
              </Panel>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </Box>
    </ThemeProvider>
  );
});

export default Studio;
