import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  alpha,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  CheckCircle,
  XCircle,
  AlertCircle,
  Terminal,
  GitBranch,
  FileText,
  ChevronRight,
  ChevronLeft,
  Code,
  AlertTriangle,
  ArrowLeft,
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { supabase } from '../supabaseClient';
import { API_URL } from '../globals';
import CodeBlock from './CodeBlock';

const EmptyState = ({ icon: Icon, title, message }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      p: 3,
      textAlign: 'center',
      color: alpha('#FFFFFF', 0.5),
    }}
  >
    <Icon size={32} strokeWidth={1.5} style={{ marginBottom: '16px' }} />
    <Typography variant="subtitle1" sx={{ mb: 1 }}>
      {title}
    </Typography>
    <Typography variant="body2" sx={{ color: alpha('#FFFFFF', 0.4) }}>
      {message}
    </Typography>
  </Box>
);

const AuditTestDetails = ({ scanId, taskId, onBack, accessToken }) => {
  const [loading, setLoading] = useState(true);
  const [taskDetails, setTaskDetails] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const [currentTerminalIndex, setCurrentTerminalIndex] = useState(0);

  const fetchTaskDetails = useCallback(async () => {
    setLoading(true);
    try {
      // Set up headers without auth token by default
      const headers = {
        'Content-Type': 'application/json'
      };
      
      // Add auth token if available from props
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      } else {
        const token = await supabase.auth.getSession();
        headers['Authorization'] = `Bearer ${token.data.session.access_token}`;
      }
      
      const taskResponse = await fetch(`${API_URL}/audit/get_task_details?scan_id=${scanId}&task_id=${taskId}`, {
        method: 'GET',
        headers: headers
      });

      if (!taskResponse.ok) {
        throw new Error(`Failed to fetch task: ${taskResponse.status} ${taskResponse.statusText}`);
      }

      const taskData = await taskResponse.json();
      
      if (!taskData.success) {
        throw new Error(taskData.error || 'Failed to fetch task details');
      }

      console.log('Task Details Response:', taskData);
      console.log('API Response - scan_summary:', taskData.scan_summary);
      console.log('API Response - test_cases:', taskData.scan_summary?.test_cases);
      
      // Add more detailed logging
      if (!taskData.scan_summary) {
        console.warn('Warning: scan_summary is missing in API response');
      } else if (!taskData.scan_summary.test_cases || !Array.isArray(taskData.scan_summary.test_cases)) {
        console.warn('Warning: test_cases is missing or not an array in API response');
      } else if (taskData.scan_summary.test_cases.length === 0) {
        console.warn('Warning: test_cases array is empty in API response');
      } else {
        console.log('Test case structure example:', JSON.stringify(taskData.scan_summary.test_cases[0], null, 2));
      }
      
      // Set task details directly from taskData since data is not nested
      setTaskDetails({
        ...taskData,
        scan_summary: taskData.scan_summary || {},
        terminal_outputs: taskData.terminal_outputs || []
      });
      
      // Set the first test case as selected if available
      if (taskData.scan_summary?.test_cases?.length > 0) {
        console.log('Setting initial test case:', taskData.scan_summary.test_cases[0]);
        setSelectedTestCase(taskData.scan_summary.test_cases[0]);
      }
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [scanId, taskId, accessToken]);

  useEffect(() => {
    if (taskId) {
      fetchTaskDetails();
    }
  }, [taskId, fetchTaskDetails]);

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !taskDetails) {
    return (
      <EmptyState
        icon={AlertCircle}
        title="Error Loading Test Details"
        message={error || "Could not load test details"}
      />
    );
  }

  const handleNextTerminal = () => {
    setCurrentTerminalIndex((prev) => 
      prev < (taskDetails.terminal_outputs?.length || 0) - 1 ? prev + 1 : prev
    );
  };

  const handlePrevTerminal = () => {
    setCurrentTerminalIndex((prev) => prev > 0 ? prev - 1 : prev);
  };

  const getStatusIcon = (verdict) => {
    switch(verdict?.toUpperCase()) {
      case 'PASS':
        return <CheckCircle size={16} color="#4CAF50" />;
      case 'FAIL':
        return <XCircle size={16} color="#F44336" />;
      case 'WARNING':
        return <AlertTriangle size={16} color="#FF9800" />;
      default:
        return <AlertCircle size={16} color="#AAAAAA" />;
    }
  };

  const getStatusColor = (verdict) => {
    switch(verdict?.toUpperCase()) {
      case 'PASS':
        return '#4CAF50';
      case 'FAIL':
        return '#F44336';
      case 'WARNING':
        return '#FF9800';
      default:
        return '#AAAAAA';
    }
  };

  const testCases = taskDetails.scan_summary?.test_cases || [];
  const terminalOutputs = taskDetails.terminal_outputs || [];

  return (
    <Box sx={{ 
      display: 'flex', 
      height: '100%',
      bgcolor: 'background.default',
      overflow: 'hidden',
      flexDirection: 'row'
    }}>
      {/* Entity List Sidebar */}
      <Box sx={{
        width: '240px',
        minWidth: '240px',
        borderRight: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'transparent',
        height: '100%',
        overflow: 'auto'
      }}>
        <Box sx={{ 
          p: 2, 
          borderBottom: '1px solid', 
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            cursor: 'pointer',
            color: 'white',
            '&:hover': {
              color: alpha('#FFFFFF', 0.8)
            }
          }} onClick={onBack}>
            <ArrowLeft size={16} />
            <Typography variant="body2">
              Back to Audit
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 300, color: 'white' }}>
            Test Results
          </Typography>
        </Box>
        <List sx={{ flex: 1, overflow: 'auto' }}>
          {testCases.map((testCase, index) => (
            <ListItem
              key={index}
              button
              selected={selectedTestCase === testCase}
              onClick={() => setSelectedTestCase(testCase)}
              sx={{
                borderLeft: '3px solid',
                borderLeftColor: selectedTestCase === testCase 
                  ? getStatusColor(testCase.verdict)
                  : 'transparent',
                bgcolor: selectedTestCase === testCase
                  ? alpha(getStatusColor(testCase.verdict), 0.1)
                  : 'transparent',
                '&:hover': {
                  bgcolor: alpha(getStatusColor(testCase.verdict), 0.05),
                },
                color: 'white',
              }}
            >
              <ListItemIcon sx={{ minWidth: 32 }}>
                {getStatusIcon(testCase.verdict)}
              </ListItemIcon>
              <ListItemText 
                primary={testCase.title}
                primaryTypographyProps={{
                  variant: 'body2',
                  sx: { 
                    fontWeight: selectedTestCase === testCase ? 500 : 400,
                    fontSize: '0.875rem',
                    color: 'white'
                  }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Main Content Area */}
      <Box sx={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column',
        gap: 2,
        p: 2,
        overflow: 'auto',
        height: '100%',
        width: '100%'
      }}>
        {/* Title Header */}
        <Typography variant="h5" sx={{ fontWeight: 500, color: 'white', mb: 2 }}>
          {taskDetails.title || 'Audit Test Result'}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          flex: 1,
          overflow: 'visible',
          minHeight: 0,
          flexDirection: {xs: 'column', md: 'row'},
          width: '100%'
        }}>
          {/* Test Report Section */}
          <Paper sx={{ 
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            bgcolor: alpha('#FFFFFF', 0.02),
            maxHeight: '100%',
            width: {xs: '100%', md: '50%'},
            minWidth: {xs: '100%', md: '400px'},
            position: 'relative',
            zIndex: 1
          }}>
            <Box sx={{ 
              p: 2, 
              borderBottom: '1px solid',
              borderColor: 'divider',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <FileText size={18} />
              <Typography variant="subtitle1">
                Test Report
              </Typography>
            </Box>
            <Box sx={{ 
              flex: 1,
              overflow: 'auto',
              p: 3,
              '& .markdown-body': {
                color: 'text.primary',
                '& h2': {
                  borderBottom: `1px solid ${alpha('#FFFFFF', 0.1)}`,
                  pb: 1,
                  mb: 2,
                  fontWeight: 300,
                  color: 'white',
                },
                '& h3': {
                  fontWeight: 400,
                  mb: 2,
                  color: 'white',
                },
                '& code': {
                  bgcolor: 'transparent',
                  color: '#E6E6E6',
                  fontFamily: 'monospace',
                },
                '& ul, & ol': {
                  pl: 3,
                },
                '& p': {
                  color: 'white',
                  mb: 1,
                },
              },
              pb: 4,
              color: 'white'
            }}>
              {selectedTestCase ? (
                <>
                  {console.log('Selected Test Case:', selectedTestCase)}
                  {!selectedTestCase.summary && !selectedTestCase.description && !selectedTestCase.test_summary && 
                   !selectedTestCase.implementation && !selectedTestCase.test_implementation && !selectedTestCase.details && 
                   !selectedTestCase.result && !selectedTestCase.test_result && !selectedTestCase.output ? (
                    <Box sx={{ p: 2, textAlign: 'center', color: 'white' }}>
                      <AlertCircle size={24} style={{ margin: '0 auto 16px' }} />
                      <Typography variant="body1" sx={{ mb: 1 }}>
                        Test case data is missing or malformed
                      </Typography>
                      <Typography variant="body2" sx={{ color: alpha('#FFFFFF', 0.6) }}>
                        The API response doesn't contain the expected fields for this test case.
                        Please check the console for more information.
                      </Typography>
                    </Box>
                  ) : (
                    <ReactMarkdown className="markdown-body"
                      components={{
                        h2: ({node, ...props}) => <Typography variant="h5" sx={{color: 'white', mb: 2, mt: 2, fontWeight: 500, borderBottom: `1px solid ${alpha('#FFFFFF', 0.1)}`, pb: 1}} {...props} />,
                        p: ({node, ...props}) => <Typography variant="body1" sx={{color: 'white', mb: 2}} {...props} />,
                        code: ({node, inline, className, children, ...props}) => {
                          const match = /language-(\w+)/.exec(className || '');
                          return !inline && match ? (
                            <CodeBlock className={className}>
                              {String(children).replace(/\n$/, '')}
                            </CodeBlock>
                          ) : (
                            <Box component="code" sx={{
                              color: '#E6E6E6', 
                              fontFamily: 'monospace',
                              bgcolor: 'transparent'
                            }} {...props}>
                              {children}
                            </Box>
                          );
                        }
                      }}
                    >
                      {`## Summary\n
${selectedTestCase.summary || selectedTestCase.description || selectedTestCase.test_summary || 'No summary available'}

## Implementation\n
${selectedTestCase.implementation || selectedTestCase.test_implementation || selectedTestCase.details || 'No implementation available'}

## Result\n
${selectedTestCase.result || selectedTestCase.test_result || selectedTestCase.output || 'No output available'}`}
                    </ReactMarkdown>
                  )}
                </>
              ) : (
                <EmptyState
                  icon={FileText}
                  title="No Test Selected"
                  message="Select a test from the sidebar to view its report"
                />
              )}
            </Box>
          </Paper>

          {/* Code Diff and Terminal Section */}
          <Box sx={{ 
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflow: 'visible',
            height: '100%',
            maxHeight: '100%',
            width: {xs: '100%', md: '50%'},
            minWidth: {xs: '100%', md: '400px'}
          }}>
            {/* Code Diff */}
            <Paper sx={{ 
              flex: 3,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              bgcolor: alpha('#FFFFFF', 0.02),
              minHeight: 0,
              maxHeight: '100%',
              position: 'relative',
              zIndex: 1
            }}>
              <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                <GitBranch size={18} />
                <Typography variant="subtitle1">
                  Code Changes
                </Typography>
              </Box>
              <Box sx={{ 
                flex: 1,
                overflow: 'auto',
                '& pre': {
                  margin: 0,
                  borderRadius: 0,
                },
                pb: 4
              }}>
                {taskDetails?.scan_summary?.documents_diff ? (
                  <Box sx={{
                    fontFamily: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
                    fontSize: '12px',
                    lineHeight: '20px',
                    width: '100%',
                    overflow: 'auto',
                    bgcolor: '#0d1117',
                    '& .diff-header': {
                      bgcolor: '#161b22',
                      color: '#c9d1d9',
                      padding: '8px 16px',
                      borderBottom: '1px solid #30363d',
                      fontWeight: 600,
                    },
                    '& .file-header': {
                      bgcolor: alpha('#FFFFFF', 0.02),
                      color: 'white',
                      padding: '10px 16px',
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      fontWeight: 500,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    },
                    '& .file-status': {
                      color: alpha('#FFFFFF', 0.5),
                      fontSize: '11px',
                      marginLeft: '6px',
                      fontStyle: 'italic',
                    },
                    '& .file-tag': {
                      backgroundColor: theme => theme.palette.primary.main,
                      color: '#fff',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      padding: '1px 6px',
                      borderRadius: '4px',
                      marginLeft: '8px',
                    },
                    '& .diff-gutter': {
                      textAlign: 'right',
                      padding: '0 8px',
                      minWidth: '40px',
                      userSelect: 'none',
                      color: '#6e7681',
                    },
                    '& .diff-code': {
                      padding: '0 16px',
                      whiteSpace: 'pre',
                    },
                    '& .diff-line': {
                      display: 'flex',
                      borderBottom: '1px solid #21262d',
                    },
                    '& .diff-addition': {
                      bgcolor: 'rgba(46, 160, 67, 0.15)',
                      '& .diff-gutter': {
                        bgcolor: 'rgba(46, 160, 67, 0.2)',
                        color: '#2ea043',
                      },
                      '& .diff-code': {
                        color: '#adbac7',
                      },
                      '&::before': {
                        content: '""',
                        width: '4px',
                        bgcolor: '#2ea043',
                      },
                    },
                    '& .diff-deletion': {
                      bgcolor: 'rgba(248, 81, 73, 0.15)',
                      '& .diff-gutter': {
                        bgcolor: 'rgba(248, 81, 73, 0.2)',
                        color: '#f85149',
                      },
                      '& .diff-code': {
                        color: '#adbac7',
                      },
                      '&::before': {
                        content: '""',
                        width: '4px',
                        bgcolor: '#f85149',
                      },
                    },
                    '& .diff-context': {
                      bgcolor: 'transparent',
                      '& .diff-code': {
                        color: '#adbac7',
                      },
                    },
                  }}>
                    {(() => {
                      const lines = taskDetails?.scan_summary?.documents_diff.split('\n');
                      const result = [];
                      let currentIndex = 0;
                      let hasContentAfterHeader = false;
                      let pendingFileHeader = null;
                      
                      while (currentIndex < lines.length) {
                        const line = lines[currentIndex];
                        
                        // Parse file headers
                        if (line.startsWith('+++ ')) {
                          // If we have a pending file header and no content was added after it,
                          // we don't add the pending header (it was empty)
                          if (pendingFileHeader && !hasContentAfterHeader) {
                            // Skip the empty file header
                          } else if (pendingFileHeader) {
                            // Add the pending header because it has content
                            result.push(pendingFileHeader);
                          }
                          
                          const filePath = line.substring(4);
                          let fileStatus = '';
                          
                          if (filePath.includes('(New File)')) {
                            fileStatus = 'New File';
                          } else if (filePath.includes('(Deleted)')) {
                            fileStatus = 'Deleted';
                          } else if (filePath.includes('(Renamed)')) {
                            fileStatus = 'Renamed';
                          } else if (filePath.includes('(Modified)')) {
                            fileStatus = 'Modified';
                          }
                          
                          // Clean file path
                          const cleanPath = filePath.replace(/ \(New File\)| \(Deleted\)| \(Renamed\)| \(Modified\)/g, '');
                          // Remove the 'b/' prefix if it exists
                          const displayPath = cleanPath.startsWith('b/') ? cleanPath.substring(2) : cleanPath;
                          
                          // Create header but don't add it yet - wait to see if it has content
                          pendingFileHeader = (
                            <Box key={`file-${currentIndex}`} className="file-header">
                              <Code size={16} />
                              <Box component="span" sx={{ ml: 1 }}>{displayPath}</Box>
                              {fileStatus && (
                                <Box component="span" className="file-status">{fileStatus}</Box>
                              )}
                            </Box>
                          );
                          
                          // Reset the content flag for the new file
                          hasContentAfterHeader = false;
                          currentIndex++;
                          continue;
                        }
                        
                        // Handle diff hunks (@@)
                        if (line.startsWith('@@')) {
                          // If we have a diff hunk, the file has content
                          hasContentAfterHeader = true;
                          
                          // Add the pending file header if it exists
                          if (pendingFileHeader) {
                            result.push(pendingFileHeader);
                            pendingFileHeader = null;
                          }
                          
                          result.push(
                            <Box key={`hunk-${currentIndex}`} className="diff-header">
                              {line}
                            </Box>
                          );
                          currentIndex++;
                          continue;
                        }
                        
                        // Skip diff intro lines (diff --git, index, ---)
                        if (line.startsWith('diff') || line.startsWith('index') || line.startsWith('---')) {
                          currentIndex++;
                          continue;
                        }
                        
                        // Process normal diff lines
                        let lineType = 'diff-context';
                        let lineContent = line;
                        let linePrefix = ' ';
                        
                        if (line.startsWith('+')) {
                          lineType = 'diff-addition';
                          linePrefix = '+';
                          lineContent = line.substring(1);
                          hasContentAfterHeader = true;
                        } else if (line.startsWith('-')) {
                          lineType = 'diff-deletion';
                          linePrefix = '-';
                          lineContent = line.substring(1);
                          hasContentAfterHeader = true;
                        } else if (line.trim() !== '') {
                          // Count non-empty context lines as content
                          hasContentAfterHeader = true;
                        }
                        
                        // Add the pending file header if it exists and we've confirmed content
                        if (pendingFileHeader && hasContentAfterHeader) {
                          result.push(pendingFileHeader);
                          pendingFileHeader = null;
                        }
                        
                        result.push(
                          <Box key={`line-${currentIndex}`} className={`diff-line ${lineType}`}>
                            <Box className="diff-gutter">{linePrefix}</Box>
                            <Box className="diff-code">{lineContent}</Box>
                          </Box>
                        );
                        
                        currentIndex++;
                      }
                      
                      // Check if we have one last file header to add
                      if (pendingFileHeader && hasContentAfterHeader) {
                        result.push(pendingFileHeader);
                      }
                      
                      return result;
                    })()}
                  </Box>
                ) : (
                  <EmptyState
                    icon={GitBranch}
                    title="No Code Changes"
                    message="No code changes were detected in this test run"
                  />
                )}
              </Box>
            </Paper>

            {/* Terminal Output Section
            <Paper sx={{ 
              flex: 2,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              bgcolor: alpha('#000000', 0.3),
              minHeight: 0
            }}>
              <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                bgcolor: alpha('#000000', 0.2),
              }}>
                <Terminal size={18} />
                <Typography variant="subtitle1" sx={{ flex: 1 }}>
                  Terminal Output
                </Typography>
                {terminalOutputs.length > 1 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton 
                      size="small" 
                      onClick={handlePrevTerminal}
                      disabled={currentTerminalIndex === 0}
                      sx={{ 
                        color: 'white',
                        '&.Mui-disabled': {
                          color: alpha('#FFFFFF', 0.3)
                        }
                      }}
                    >
                      <ChevronLeft size={16} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: alpha('#FFFFFF', 0.7) }}>
                      {currentTerminalIndex + 1} / {terminalOutputs.length}
                    </Typography>
                    <IconButton 
                      size="small" 
                      onClick={handleNextTerminal}
                      disabled={currentTerminalIndex === terminalOutputs.length - 1}
                      sx={{ 
                        color: 'white',
                        '&.Mui-disabled': {
                          color: alpha('#FFFFFF', 0.3)
                        }
                      }}
                    >
                      <ChevronRight size={16} />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Box sx={{ 
                flex: 1,
                overflow: 'auto',
                fontFamily: 'monospace',
                fontSize: '0.875rem',
                p: 2,
                whiteSpace: 'pre-wrap',
                color: '#E6E6E6',
              }}>
                {terminalOutputs.length > 0 ? (
                  terminalOutputs[currentTerminalIndex]?.content?.data?.output || 'No output available'
                ) : (
                  <EmptyState
                    icon={Terminal}
                    title="No Terminal Output"
                    message="No terminal output was captured during this test run"
                  />
                )}
              </Box>
            </Paper> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuditTestDetails; 