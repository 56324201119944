import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';
import { supabase } from "../supabaseClient";
import { API_URL } from "../globals";
import axios from "axios";

const PlaybookModal = ({ open, onClose, mode = 'create', initialData = null, onSave }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [studioId, setStudioId] = useState('');
  const [studios, setStudios] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.name || '');
      setContent(initialData.content || '');
      setStudioId(initialData.studio_id || '');
    }
  }, [initialData]);

  useEffect(() => {
    if (open) {
      fetchStudios();
    }
  }, [open]);

  const fetchStudios = async () => {
    setLoading(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/studio/seeds`, { headers });
      
      if (response.data) {
        const availableStudios = response.data.filter(studio => 
          studio.status === "READY" || studio.status === "BUSY"
        );
        setStudios(availableStudios);
        
        // Set default studio if creating new playbook and there are available studios
        if (mode === 'create' && availableStudios.length > 0 && !studioId) {
          setStudioId(availableStudios[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch studios:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    onSave({
      title,
      content,
      studio_id: studioId,
    });
    handleClose();
  };

  const handleClose = () => {
    setTitle('');
    setContent('');
    setStudioId('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          border: '1px solid #333',
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ 
        color: '#fff',
        borderBottom: '1px solid #333',
        p: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {mode === 'create' ? 'Create New Playbook' : 'Edit Playbook'}
        </Typography>
        <IconButton 
          onClick={handleClose} 
          size="small" 
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{
              mt: 1,
              flex: 2,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                backgroundColor: '#121212',
                '& fieldset': {
                  borderColor: '#333',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#666',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#666',
              },
            }}
          />

          <FormControl 
            sx={{ 
              mt: 1,
              flex: 1,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                backgroundColor: '#121212',
                '& fieldset': {
                  borderColor: '#333',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
              },
            }}
          >
            <InputLabel 
              id="studio-select-label"
              sx={{ 
                color: '#666',
                '&.Mui-focused': {
                  color: '#666',
                },
              }}
            >
              Select Studio
            </InputLabel>
            <Select
              labelId="studio-select-label"
              value={studioId}
              onChange={(e) => setStudioId(e.target.value)}
              label="Select Studio"
              disabled={loading || mode === 'edit'}
              sx={{
                color: '#fff',
                '& .MuiSelect-icon': {
                  color: '#666',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#1a1a1a',
                    border: '1px solid #333',
                    borderRadius: 1,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                    '& .MuiMenuItem-root': {
                      color: '#fff',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: '#252525',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#252525',
                        '&:hover': {
                          backgroundColor: '#2a2a2a',
                        },
                      },
                    },
                  },
                },
              }}
            >
              {loading ? (
                <MenuItem disabled>
                  <CircularProgress size={20} sx={{ color: '#666' }} />
                </MenuItem>
              ) : (
                studios.map((studio) => (
                  <MenuItem 
                    key={studio.id} 
                    value={studio.id}
                    sx={{
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#252525',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#252525',
                      },
                    }}
                  >
                    {studio.display_name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ 
          '& .w-md-editor': {
            backgroundColor: '#121212',
            color: '#fff',
            border: '1px solid #333',
            borderRadius: 1,
          },
          '& .w-md-editor-text-pre > code': {
            color: '#fff !important',
          },
          '& .w-md-editor-text-input': {
            color: '#fff !important',
          },
          '& .wmde-markdown-color': {
            backgroundColor: '#121212 !important',
            color: '#fff !important',
          },
          '& .w-md-editor-toolbar': {
            backgroundColor: '#121212 !important',
            borderBottom: '1px solid #333',
          },
          '& .w-md-editor-toolbar li > button': {
            color: '#666 !important',
            '&:hover': {
              color: '#fff !important',
            },
          },
        }}>
          <MDEditor
            value={content}
            onChange={setContent}
            height={400}
            preview="edit"
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        p: 3, 
        borderTop: '1px solid #333',
        gap: 1
      }}>
        <Button 
          onClick={handleClose}
          sx={{
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: 'transparent',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={!title || !content || !studioId}
          sx={{
            border: '1px solid #333',
            backgroundColor: '#121212',
            color: '#fff',
            px: 3,
            py: 1.5,
            textTransform: 'none',
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: '#252525',
              borderColor: '#666',
            },
            '&.Mui-disabled': {
              backgroundColor: '#1a1a1a',
              color: '#666',
              borderColor: '#333',
            },
          }}
        >
          {mode === 'create' ? 'Create Playbook' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlaybookModal;