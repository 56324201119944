import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { BookMarked, ChevronUp, Check } from 'lucide-react';

const SaveKnowledgeCard = ({
  content,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showContent, setShowContent] = useState(!animate);
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed);
  const [typedContent, setTypedContent] = useState(animate ? '' : content);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(animate);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowContent(true);
      setCursorVisible(false);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => {
        setShowContent(true);
        // Show save success animation after reveal
        setShowSaveSuccess(true);
        setTimeout(() => setShowSaveSuccess(false), 2000);
      }, 300);
    }, 1000);

    // Cursor blink effect
    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearTimeout(revealTimer);
      clearInterval(cursorInterval);
    };
  }, [animate]);

  // Type content effect
  useEffect(() => {
    if (!animate || !showContent) return;

    if (typedContent.length < content.length) {
      const typeTimer = setTimeout(() => {
        setTypedContent(content.slice(0, typedContent.length + 1));
      }, 30);
      return () => clearTimeout(typeTimer);
    }
  }, [showContent, typedContent, content, animate]);

  useEffect(() => {
    setIsCollapsed(externalCollapsed);
  }, [externalCollapsed]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          '&::after': showSaveSuccess ? {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '2px',
            background: 'linear-gradient(90deg, transparent, #4caf50, transparent)',
            animation: 'saveProgress 2s ease-in-out',
          } : {},
          '@keyframes saveProgress': {
            '0%': {
              left: '-100%',
              right: '100%',
            },
            '50%': {
              left: '0%',
              right: '0%',
            },
            '100%': {
              left: '100%',
              right: '-100%',
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                position: 'relative',
                width: '20px',
                height: '20px',
                mr: 1.5,
              }}
            >
              <BookMarked
                size={20}
                style={{
                  color: '#4caf50',
                  position: 'absolute',
                  opacity: showSaveSuccess ? 0 : 1,
                  transform: showSaveSuccess ? 'scale(0.8)' : 'scale(1)',
                  transition: 'all 0.3s ease-in-out',
                }}
              />
              <Check
                size={20}
                style={{
                  color: '#4caf50',
                  position: 'absolute',
                  opacity: showSaveSuccess ? 1 : 0,
                  transform: `scale(${showSaveSuccess ? 1 : 0.8})`,
                  transition: 'all 0.3s ease-in-out',
                }}
              />
            </Box>
            <Typography
              component="span"
              sx={{
                fontSize: '0.875rem',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              Saved to Knowledge Base
              <Box
                sx={{
                  height: '6px',
                  width: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#4caf50',
                  opacity: showSaveSuccess ? 1 : 0,
                  transform: `scale(${showSaveSuccess ? 1 : 0})`,
                  transition: 'all 0.3s ease-in-out',
                }}
              />
            </Typography>
          </Box>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {!isCollapsed && (
          <Box
            sx={{
              mt: 2,
              opacity: showContent ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              backgroundColor: 'rgba(76, 175, 80, 0.1)',
              borderRadius: 1,
              p: 2,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(45deg, transparent 65%, rgba(76, 175, 80, 0.1) 80%, transparent 90%)',
                backgroundSize: '200% 200%',
                animation: 'shimmer 3s infinite',
                pointerEvents: 'none',
              },
              '@keyframes shimmer': {
                '0%': {
                  backgroundPosition: '200% 0',
                },
                '100%': {
                  backgroundPosition: '-200% 0',
                },
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: '#fff',
                fontFamily: 'monospace',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {typedContent}
              {cursorVisible && typedContent.length < content.length && (
                <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SaveKnowledgeCard; 